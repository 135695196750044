import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { getUrl } from 'Router/urlConfig';
import { useUserData } from 'Contexts/UserContext';
import { saveDataInLocalStorage, removeDataInLocalStorage } from 'dist/Storage/localStorageMethods';

function Blank() {
  const { token } = useParams();
  const { setUserData, setLoading } = useUserData();
  const history = useHistory();

  const apiURL = getUrl('api');

  const loginPanel = async () => {
    const response = await axios.post(`${apiURL}/login/panel`, {}, {
      headers: {
        authorization: token,
        'Content-Type': 'application/json',
      },
    });

    return response;
  };

  useEffect(async () => {
    removeDataInLocalStorage('vacanted');
    removeDataInLocalStorage('firebase-info');
    await loginPanel()
      .then((response) => {
        if (response.status === 201) {
          setUserData({ loginStatus: true, data: response.data });
          saveDataInLocalStorage('vacanted', response.data);
          setLoading(false);

          history.push('/centro/profile');
        }
        return response;
      });

    return false;
  }, []);

  return (
    <div>
      {/* <h1>teste</h1> */}
    </div>
  );
}

export default Blank;
