import api from 'Services/api';

export default async function getSchoolVacantestsByDate(range, schoolId) {
  const body = {
    parameters: {
      dateRange: range,
    },
  };
  const datafetched = await api.post(`/report/schools/${schoolId}/vacantests`, body).then((res) => res.data).catch((err) => err);
  return datafetched;
}
