const ReferralAlerts = {
  NEW_TEST: 'NEW_TEST',
  NEW_CONTACT: 'NEW_CONTACT',
  NEW_INTERVIEW: 'NEW_INTERVIEW',
  NEW_POSTULATION: 'NEW_POSTULATION',
  UNREAD_MESSAGES: 'UNREAD_MESSAGES',
};

const ReferralStates = {
  NEW: {
    name: 'Nueva',
    filterName: 'Nuevas',
    stateId: 1,
  },
  APPROACHED: {
    name: 'Información enviada',
    filterName: 'Con información enviada',
    stateId: 2,
  },
  CHATTING: {
    name: 'En contacto',
    filterName: 'En contacto',
    stateId: 4,
  },
  INTERVIEWING: {
    name: 'En proceso de entrevistas',
    filterName: 'En proceso de entrevistas',
    stateId: 6,
  },
  POSTULATED: {
    name: 'Postulada',
    filterName: 'Postuladas',
    stateId: 8,
  },
  REGISTERED: {
    name: 'Matriculada',
    filterName: 'Matriculadas',
    stateId: 10,
    final: true,
  },
  NOT_REGISTERED: {
    name: 'No matriculada',
    filterName: 'No matriculadas',
    stateId: 11,
    final: true,
  },
  DISCARDED: {
    name: 'Descartada',
    filterName: 'Descartadas',
    stateId: 12,
    final: true,
  },
};

const stateById = (aStateId) => Object.values(ReferralStates)
  .find(({ stateId }) => stateId === aStateId);

export default {
  ReferralAlerts,
  ReferralStates,
  stateById,
};
