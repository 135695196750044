import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Table,
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import editIcon from 'assets/img/edit_black_filled_24dp.svg';

import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import { useAdminData } from 'Contexts/AdminContext';
import api from 'Services/api';
import styles from './styles.module.css';

export default function UsersTable({ data, setForceUpdate }) {
  const {
    filteredData,
    setIsEditing,
    setIsCreating,
    isFiltering,
    setPayload,
  } = useAdminData();

  async function handleDisable(user) {
    const { id, email } = user;
    const userInLocalStorage = await getDataFromLocalStorage('vacanted');
    const { token } = userInLocalStorage;

    const headers = {
      headers: {
        authorization: token,
      },
    };

    await api.patch('/user/disable', {
      id,
    }, headers).then((res) => res).then(() => toast.info(`Usuario ${email} inactivado`)).catch((err) => err);
    return setForceUpdate(1);
  }

  async function handleEnable(user) {
    const userInLocalStorage = await getDataFromLocalStorage('vacanted');
    const { id, email } = user;
    const { token } = userInLocalStorage;
    const headers = {
      headers: {
        authorization: token,
      },
    };

    await api.put('/user/update', {
      id,
      data: {
        active: true,
      },
    }, headers).then((res) => res).then(() => toast.info(`Usuario ${email} activado`)).catch((err) => err);
    return setForceUpdate(1);
  }

  function renderTableBody(arr) {
    return arr.map((user) => (
      <tr key={user.email}>
        <td>{user.schoolId || '-'}</td>
        <td>{user.email}</td>
        <td>{!user.schoolName ? '-' : user.schoolName}</td>
        <td>{user.roleName}</td>
        <td>
          <button
            type="button"
            className={styles['table-edit-icon']}
            onClick={() => {
              setIsEditing(true);
              setIsCreating(false);
              setPayload({
                id: user.id,
                name: user.name,
                email: user.email,
                password: user.password,
                role: user.roleId,
                schoolId: user.schoolId,
                schoolName: user.schoolName,
              });
            }}
          >
            {<img src={editIcon} alt="Editar" /> || 'Editar'}
          </button>
          {user.active ? (
            <Button
              variant="danger"
              size="sm"
              style={{
                backgroundColor: '#d51e52',
                border: 'none',
                fontWeight: '500',
              }}
              onClick={() => handleDisable(user)}
            >
              INACTIVAR
            </Button>
          ) : (
            <Button
              variant="primary"
              size="sm"
              style={{
                border: 'none',
                fontWeight: '500',
              }}
              onClick={() => handleEnable(user)}
            >
              ACTIVAR
            </Button>
          )}
        </td>
      </tr>
    ));
  }
  return (
    <Card body>
      <div className={styles['table-container']}>
        <Table id="users-table" hover responsive>
          <thead>
            <tr>
              <th>ID Vacanted</th>
              <th>E-mail usuario admin</th>
              <th>Nombre intitución</th>
              <th>Credenciales</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>{isFiltering ? renderTableBody(filteredData) : renderTableBody(data)}</tbody>
        </Table>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        rtl={false}
        closeOnClick
        hideProgressBar
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Card>
  );
}

UsersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}.isRequired;
