import axios from 'axios';
import { getUrl } from 'Router/urlConfig';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';

export async function getSchoolApplication(schoolId) {
  const { token } = await getDataFromLocalStorage('vacanted');
  const apiURL = getUrl('api');

  return axios.get(`${apiURL}/postulate/getSchoolPostulations/${schoolId}`, {
    headers: {
      authorization: token,
    },
  })
    .then((resp) => resp.data)
    .catch((error) => error);
}

export async function applicationsOptions() {
  const apiURL = getUrl('api');

  return axios.get(`${apiURL}/postulate/postulationstatus`)
    .then((resp) => resp.data)
    .catch((err) => err);
}

export async function updateApplicationStatus(idPostulation, status) {
  const { token } = await getDataFromLocalStorage('vacanted');
  const apiURL = getUrl('api');

  const config = {
    headers: {
      authorization: token,
    },
  };

  const data = {
    id: idPostulation,
    status,
  };

  return axios.put(`${apiURL}/postulate/edit`, data, { ...config })
    .then((resp) => resp.data)
    .catch((error) => error);
}
