import React, { useState, useEffect } from 'react';
import api from 'Services/api';
import Modal from 'react-modal';
import getUserData from 'hooks/getUserData';
import styles from './styles.module.css';

Modal.setAppElement('#root');

function createVisitModal({
  isOpen, onRequestClose, visits, setVisits,
}) {
  const [date, setDate] = useState();
  const [type, setType] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [schoolId, setSchoolId] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    async function getUser() {
      const user = await getUserData();
      setToken(user.data.token);
      setSchoolId(user.data.user.school_id);
    }
    getUser();
  }, []);
  const handleSubmit = async () => {
    if (!date || !type) {
      setErrorMessage('fill');
      return;
    }
    if (new Date(date) < Date.now()) {
      setErrorMessage('date');
    }
    const data = {
      date: new Date(date),
      type,
      school_id: schoolId,
    };
    const iteratorData = {
      id: visits[visits.length - 1].id + 1,
      date,
      type,
    };
    setVisits([...visits, iteratorData]);

    const config = {
      headers: {
        authorization: token,
      },
    };
    await api.post('/school/calendar', data, config);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={styles.react_modal_overlay}
      className={styles.react_modal_content}
    >
      <div className={styles.header_keep}>
        <div className={styles.header_close}>
          <h2>Crear Visita</h2>
          <button
            type="button"
            onClick={onRequestClose}
            className={styles.modal_close}
          >
            X
          </button>
        </div>
      </div>
      <form>
        {errorMessage === 'fill' && <h4>Preencha todos os campos</h4>}
        {errorMessage === 'date' && <h4>Não é possível criar datas já passadas</h4>}
        <p>Data y Hora:</p>
        <input
          className={styles.input}
          type="datetime-local"
          onChange={(e) => setDate(e.target.value)}
        />
        <div className={styles.schoolsContainer}>
          <p>Tipo de visita:</p>
          <select onChange={(e) => setType(e.target.value)}>
            <option value="Presencial" selected>Presencial</option>
            <option value="Virtual">Virtual</option>
          </select>
        </div>

        <button
          type="button"
          className={styles.keep_button}
          onClick={handleSubmit}
        >
          CREAR VISITA
        </button>
      </form>
    </Modal>
  );
}

export default createVisitModal;
