import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Chart from './Chart';
import HighlightedMetrics from './HighlightedMetrics';
import ReferralsSummary from './ReferralsSummary';
import getSchoolDashboardData from '../../hooks/getSchoolDashboardData';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    getSchoolDashboardData().then(setData);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900]
            ),
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
          >
            {!data && <CircularProgress />}
            {data && (
              <Grid
                container
                spacing={3}
              >
                {/* HighlightedMetrics */}
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <HighlightedMetrics referralsByState={data.referrals} />
                  </Paper>
                </Grid>
                {/* Families */}
                <Grid item xs={12} md={8} lg={9}>
                  <Paper sx={{
                    p: 2, display: 'flex', flexDirection: 'column', height: 240,
                  }}
                  >
                    <ReferralsSummary referralsByState={data.referrals} />
                  </Paper>
                </Grid>
                {/* Chart */}
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <Chart recommendationsByMonth={data.recommendations} />
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
