import React from 'react';
import PropTypes from 'prop-types';
import ReferralStateTag from '../../../../ReferralStateTag/ReferralStateTag';

const StateCell = ({ value }) => (
  <td>
    <ReferralStateTag stateId={value} />
  </td>
);

StateCell.propTypes = {
  value: PropTypes.number.isRequired,
};

export default StateCell;
