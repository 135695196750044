import React, { useState, useEffect } from 'react';
import {
  Button, Container, Card, Col, Row, Form,
} from 'react-bootstrap';
import { formatISO9075 } from 'date-fns';
import Select from 'react-select';

import dateRange from 'dist/Utils/dateRanges';
import getReportByDateFilter from 'hooks/getReportByDateFilter';

import { useAdminData } from 'Contexts/AdminContext';
import SchoolReportPDF from './components/SchoolReportPDF';

const customDateInitialState = {
  startDate: '2021-01-01',
  endDate: formatISO9075(new Date(), { representation: 'date' }),
};

export default function ReportBySchool() {
  const [data, setData] = useState();
  const [schoolOptions, setSchoolOptions] = useState();
  const [selectedSchool, setSelectedSchool] = useState();
  const [reportFilter, setReportFilter] = useState();
  const [dateFilter, setDateFilter] = useState('all_history');
  const [customDateRange, setCustomDateRange] = useState(customDateInitialState);

  const { loggedUser, country } = useAdminData();

  function getSchoolOptions(schools) {
    const options = schools.map((school) => ({ value: school['ID Vacanted'], label: `${school['ID Vacanted']} - ${school['Nombre del Centro']}` }));
    return setSchoolOptions(options);
  }

  const reportOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'views', label: 'Visualizaciones de ficha' },
    { value: 'interviews', label: 'Solicitud de entrevistas' },
    { value: 'consultings', label: 'Envio de consulta' },
    { value: 'visit_requests', label: 'Solicitud de agenda de visita guiada' },
    { value: 'postulations', label: 'Postulaciones solicitadas' },
    { value: 'vacantest_results_5', label: 'Vacantest hasta 5' },
    { value: 'vacantest_results_15', label: 'Vacantest hasta 15' },
    { value: 'vacantest_results_50', label: 'Vacantest hasta 50' },
    { value: 'search_results_5', label: 'Búsquedas guardadas hasta 5' },
    { value: 'search_results_15', label: 'Búsquedas guardadas hasta 15' },
    { value: 'search_results_50', label: 'Búsquedas guardadas hasta 50' },
  ];

  const dateOptions = [
    { value: 'all_history', label: 'Todo el historial' },
    { value: 'all_year', label: 'Todo el año' },
    { value: 'last_six_months', label: 'Últimos 6 meses' },
    { value: 'last_three_months', label: 'Últimos 3 meses' },
    { value: 'last_thirty_days', label: 'Últimos 30 días' },
    { value: 'custom', label: 'Periodo personalizado' },
  ];

  function filterData() {
    const schoolData = data.filter((school) => school['ID Vacanted'] === selectedSchool);

    const filteredResult = schoolData.map((item) => {
      const result = {};
      result.username = loggedUser.name;
      result.schoolName = item['Nombre del Centro'];
      result.info = [];

      let range = {};
      if (dateFilter === 'all_history') range = dateRange.allHistory;
      if (dateFilter === 'all_year') range = dateRange.allYear;
      if (dateFilter === 'last_six_months') range = dateRange.lastSixMonths;
      if (dateFilter === 'last_three_months') range = dateRange.lastThreeMonths;
      if (dateFilter === 'last_thirty_days') range = dateRange.lastThirtyDays;
      if (dateFilter === 'custom') range = customDateRange;
      result.period = range;

      for (let i = 0; i <= reportFilter.length; i += 1) {
        if (reportFilter[i] === 'Todos') {
          for (let j = 1; j < reportOptions.length; j += 1) {
            if (reportOptions[j].label !== 'Todos') {
              result.info.push({ [reportOptions[j].label]: item[reportOptions[j].label] });
            }
          }
        }
        if (reportFilter[i] && reportFilter[i] !== 'Todos') {
          result.info.push({ [reportFilter[i]]: item[reportFilter[i]] });
        }
      }
      return result;
    });
    return filteredResult;
  }

  function handleMultiSelectChange(e) {
    const selectValues = e.map((item) => item.label);
    return setReportFilter(selectValues);
  }

  function handleDateSelectChange(e) {
    const { value } = e;
    return setDateFilter(value);
  }

  function handleSchoolSelectChange(e) {
    return setSelectedSchool(e.value);
  }

  function handleInitialDateChange(e) {
    const { value } = e.target;
    return setCustomDateRange({ ...customDateRange, startDate: value });
  }

  function handleFinalDateChange(e) {
    const { value } = e.target;
    return setCustomDateRange({ ...customDateRange, endDate: value });
  }

  function handleClick() {
    const reportData = filterData();
    return SchoolReportPDF(reportData);
  }

  useEffect(async () => {
    const fetchData = await getReportByDateFilter(
      dateFilter, country.id, dateRange, customDateRange,
    );

    return getSchoolOptions(fetchData);
  }, [country]);

  useEffect(async () => {
    const fetchData = await getReportByDateFilter(
      dateFilter, country.id, dateRange, customDateRange,
    );

    return setData(fetchData);
  }, [dateFilter, customDateRange]);

  return (
    <Container style={{ height: '100vh' }}>
      <h1 style={{ margin: '0', padding: '30px 0 15px 0' }}>Reportes Globales por Centro Educativo</h1>
      { !data
        ? <p>Loading...</p>
        : (
          <Card style={{ padding: '0 1rem' }}>
            <Row style={{ padding: '1rem 0' }}>
              <Col xs={2}>
                <Form.Group>
                  <Form.Control type="text" value={country.name} readOnly />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Select
                    options=""
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccionar ciudad"
                    onChange=""
                    isDisabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Label>ID VACANTED o Nombre Institución</Form.Label>
                <Select
                  placeholder="ID - Nombre"
                  options={schoolOptions}
                  name="schoolId"
                  onChange={(e) => handleSchoolSelectChange(e)}
                />
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>Datos a visualizar en el reporte</Form.Label>
                  <Select
                    isDisabled={!selectedSchool}
                    options={reportOptions}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => handleMultiSelectChange(e)}
                    placeholder="Seleccionar opciones"
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>Indique periodo de tiempo</Form.Label>
                  <Select
                    isDisabled={!selectedSchool && !reportFilter}
                    options={dateOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccionar opción"
                    onChange={(e) => handleDateSelectChange(e)}
                  />
                </Form.Group>
                {
                  (dateFilter === 'custom')
                    ? (
                      <>
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Desde</Form.Label>
                              <Form.Control type="date" onChange={(e) => handleInitialDateChange(e)} />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Hasta</Form.Label>
                              <Form.Control type="date" onChange={(e) => handleFinalDateChange(e)} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )
                    : ''
                }
              </Col>
            </Row>
            <Row style={{ padding: '1rem 0', textAlign: 'right' }}>
              <Col>
                <Button style={{ backgroundColor: '#36a7e8', border: 'none' }} onClick={() => handleClick()}>EXPORTAR PDF</Button>
              </Col>
            </Row>
          </Card>
        )}
    </Container>
  );
}
