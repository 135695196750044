import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const actionNames = {
  NEW_CONTACT: 'Responder nueva consulta',
  NEW_TEST: 'Enviar información institucional o descartar',
  NEW_INTERVIEW: 'Coordinar entrevista',
  NEW_POSTULATION: 'Analizar postulación',
  UNREAD_MESSAGES: 'Leer nuevos mensajes',
};

const AlertCell = ({ value }) => (
  <td>
    {value && (
    <>
      <FontAwesomeIcon icon={faTriangleExclamation} color="#f8ac59" />
      {' '}
      { actionNames[value] }
    </>
    )}
  </td>
);

AlertCell.propTypes = {
  value: PropTypes.string,
};

AlertCell.defaultProps = {
  value: null,
};

export default AlertCell;
