import React, { useState, useEffect } from 'react';

import getDataOptions from 'hooks/getOptionsToDropDown';
import getSchoolInfo from 'hooks/getSchoolInfo';

import { Button } from 'react-bootstrap';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import BasicInfo from './sections/BasicInfo';
import MainFeatures from './sections/MainFeatures';
import Languages from './sections/Languages';
import Religion from './sections/Religion';
import ExtracurricularActivities from './sections/ExtracurricularActivities';

import styles from './styles.module.css';

export default function EditFicha() {
  const [options, setOptions] = useState();
  const [capturingData, setCapturingData] = useState({
    saveInProgress: false,
  });
  const [schoolData, setSchoolData] = useState();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    message: '',
    type: '',
  });

  const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(async () => {
    const data = await getDataOptions();
    const school = await getSchoolInfo();

    setSchoolData(school);
    setOptions(data);
  }, []);

  if (!options) return <p>loading...</p>;
  if (!schoolData) return <Alert className="mt-2" severity="info">¡No hay ninguna escuela asociada con este usuario!</Alert>;

  return (
    <div className={styles.container}>
      <h1>Edicion de la Ficha de Centro Educativo</h1>

      <div className={styles.content}>
        <div className={`${styles.leftSide} ${styles.division}`}>
          <p>Informacion básica</p>
        </div>
        <div className={`${styles.contents} ${styles.division}`}>
          <BasicInfo
            infoCentro={schoolData}
            capturingData={capturingData}
            setCapturingData={setCapturingData}
            setOpen={setOpen}
            setAlert={setAlert}
          />
        </div>
        <div className={`${styles.enableEdit} ${styles.division}`} style={{ display: capturingData.basicInfos?.saveInProgress ? 'none' : 'block' }}>
          <Button
            onClick={() => setCapturingData(
              {
                ...capturingData,
                saveInProgress: true,
                basicInfos: { ...capturingData.basicInfos, saveInProgress: true },
              },
            )}
          >
            Editar

          </Button>
        </div>
      </div>

      <div className={styles.content}>
        <div className={`${styles.leftSide} ${styles.division}`}>
          <p>Características principales</p>
        </div>
        <div className={`${styles.contents} ${styles.division}`}>
          <MainFeatures
            infoCentro={schoolData}
            educationalManagementOptions={options.publicOptions}
            typeOfUrbanizationOptions={options.urbanOptions}
            costOptions={options.costOptions}
            genreOptions={options.genreOptions}
            activitiesOptions={options.activitiesOptions}
            capturingData={capturingData}
            setCapturingData={setCapturingData}
            setOpen={setOpen}
            setAlert={setAlert}
          />
        </div>
        <div className={`${styles.enableEdit} ${styles.division}`} style={{ display: capturingData.mainFeature?.saveInProgress ? 'none' : 'block' }}>
          <Button
            onClick={() => setCapturingData(
              {
                ...capturingData,
                saveInProgress: true,
                mainFeature: { ...capturingData.mainFeature, saveInProgress: true },
              },
            )}
          >
            Editar

          </Button>
        </div>
      </div>

      <div className={styles.content}>
        <div className={`${styles.leftSide} ${styles.division}`}>
          <p>Idiomas</p>
        </div>
        <div className={`${styles.contents} ${styles.division}`}>
          <Languages
            infoCentro={schoolData}
            languagesOptions={options.languagesOptions}
            capturingData={capturingData}
            setCapturingData={setCapturingData}
          />
        </div>
        <div className={`${styles.enableEdit} ${styles.division}`} style={{ display: capturingData.language?.saveInProgress ? 'none' : 'block' }}>
          <Button
            onClick={() => setCapturingData(
              {
                ...capturingData,
                saveInProgress: true,
                language: { ...capturingData.language, saveInProgress: true },
              },
            )}
          >
            Editar

          </Button>
        </div>
      </div>

      <div className={styles.content}>
        <div className={`${styles.leftSide} ${styles.division}`}>
          <p>Religión</p>
        </div>
        <div className={`${styles.contents} ${styles.division}`}>
          <Religion
            infoCentro={schoolData}
            religionOptions={options.religionOptions}
            capturingData={capturingData}
            setCapturingData={setCapturingData}
            setOpen={setOpen}
            setAlert={setAlert}
          />
        </div>
        <div className={`${styles.enableEdit} ${styles.division}`} style={{ display: capturingData.religion?.saveInProgress ? 'none' : 'block' }}>
          <Button
            onClick={() => setCapturingData(
              {
                ...capturingData,
                saveInProgress: true,
                religion: { ...capturingData.religion, saveInProgress: true },
              },
            )}
          >
            Editar

          </Button>
        </div>
      </div>

      <div className={styles.content}>
        <div className={`${styles.leftSide} ${styles.division}`}>
          <p>Actividades extracurriculares</p>
        </div>
        <div className={`${styles.contents} ${styles.division}`}>
          <ExtracurricularActivities
            infoCentro={schoolData}
            educationalManagementOptions={options.publicOptions}
            typeOfUrbanizationOptions={options.urbanOptions}
            costOptions={options.costOptions}
            genreOptions={options.genreOptions}
            activitiesOptions={options.activitiesOptions}
            capturingData={capturingData}
            setCapturingData={setCapturingData}
          />
        </div>
        <div className={`${styles.enableEdit} ${styles.division}`} style={{ display: capturingData.extracurricularActivities?.saveInProgress ? 'none' : 'block' }}>
          <Button
            onClick={() => setCapturingData(
              {
                ...capturingData,
                saveInProgress: true,
                extracurricularActivities: {
                  ...capturingData.extracurricularActivities,
                  saveInProgress: true,
                },
              },
            )}
          >
            Editar

          </Button>
        </div>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
              {alert.message}
            </Alert>
          </Snackbar>
        </Stack>
      </div>
    </div>
  );
}
