import React from 'react';
import PropTypes from 'prop-types';

const CreatedAtCell = ({ value }) => (
  <td>
    {value}
  </td>
);

CreatedAtCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CreatedAtCell;
