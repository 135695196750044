// import urlConfig from 'Router/urlConfig';

export const getDataFromLocalStorage = (key = 'vacanted') => {
  const value = localStorage.getItem(key);

  return JSON.parse(value);
};

export const getSchoolIdFromStorage = () => {
  const { user } = getDataFromLocalStorage();
  return user?.school_id;
};

export function saveDataInLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function removeDataInLocalStorage(key) {
  localStorage.removeItem(key);
  // window.location.replace(urlConfig.environment === 'local'
  //   ? urlConfig.local.hub
  //   : urlConfig.development.hub);
}
