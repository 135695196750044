import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export default function InputTextWithTitle({
  title, value, rewrite, action, placeholder, name,
}) {
  if (rewrite) {
    return (
      <div className={`${styles.titleContent}`}>
        <h3>{title}</h3>
        <input placeholder={placeholder} type="text" value={value} onChange={action} name={name} />
      </div>
    );
  }

  return (
    <div className={`${styles.titleContent}`}>
      <h3>{title}</h3>
      <input placeholder={placeholder} type="text" value={value} readOnly />
    </div>
  );
}

InputTextWithTitle.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rewrite: PropTypes.bool,
  action: PropTypes.func,
}.isRequired;
