/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import Title from './Title';

export default function HighlightedMetrics({ referralsByState }) {
  return (
    <>
      <Title>Resumen</Title>
      <Typography component="p" variant="h4">
        {referralsByState.reduce((acc, { total }) => acc + total, 0)}
      </Typography>
      <Typography color="text.secondary">
        familias referidas
      </Typography>
      {/* <Divider sx={{ my: 1, borderBottomWidth: 'medium' }} variant="" />
      <Typography component="p" variant="h4">
        5
      </Typography>
      <Typography color="text.secondary">
        solicitudes de entrevista
      </Typography> */}
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        en los últimos 30 días
      </Typography> */}
    </>
  );
}

HighlightedMetrics.propTypes = {
  referralsByState: PropTypes.arrayOf(PropTypes.shape({
    state_id: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  })).isRequired,
};
