const getPositionType = (value) => {
  if (value === 1) {
    return 'success';
  }
  if (value > 1 && value < 4) {
    return 'primary';
  }

  return 'light';
};

export default getPositionType;
