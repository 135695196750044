import React from 'react';

import PropTypes from 'prop-types';

import TableReceivedApplications from 'components/TableReceivedApplications';

export default function ApplicationsCompleted({ data }) {
  return (
    <div>
      <h4>
        Postulaciones finalizadas
      </h4>
      <p>
        Historial de los ya finalizados
        (postulación aceptada, o rechazada o caducados), solo para visualizar.
      </p>
      <TableReceivedApplications data={data} type="ApplicationsCompleted" />
    </div>
  );
}

ApplicationsCompleted.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}.isRequired;
