import { useState } from 'react';

export default function useLanguages(infoCentro) {
  const [useLanguage1, setUseLanguage1] = useState({
    value: infoCentro?.language1?.id,
    label: infoCentro?.language1?.name,
  });

  const [useLanguage2, setUseLanguage2] = useState({
    value: infoCentro?.language2?.id,
    label: infoCentro?.language2?.name,
  });

  const [useLanguage3, setUseLanguage3] = useState({
    value: infoCentro?.language3?.id,
    label: infoCentro?.language3?.name,
  });

  const [useLanguage4, setUseLanguage4] = useState({
    value: infoCentro?.languages[0]?.language_id,
    label: infoCentro?.languages[0]?.language,
  });

  const [useLanguage5, setUseLanguage5] = useState({
    value: infoCentro?.languages[1]?.language_id,
    label: infoCentro?.languages[1]?.language,
  });

  const [useLanguage6, setUseLanguage6] = useState({
    value: infoCentro?.languages[2]?.language_id,
    label: infoCentro?.languages[2]?.language,
  });

  return {
    useLanguage1,
    setUseLanguage1,
    useLanguage2,
    setUseLanguage2,
    useLanguage3,
    setUseLanguage3,
    useLanguage4,
    setUseLanguage4,
    useLanguage5,
    setUseLanguage5,
    useLanguage6,
    setUseLanguage6,
  };
}
