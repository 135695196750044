import ReferralConstants from '../constants/referrals';

export const doReferralsInsert = (referralList) => ({
  type: 'INSERT_REFERRALS',
  payload: referralList,
});

export const doReferralDetailsInsert = (referralDetails) => ({
  type: 'INSERT_REFERRAL_DETAILS',
  payload: referralDetails,
});

export const doReferralDetailsDelete = () => ({
  type: 'DELETE_REFERRAL_DETAILS',
});

export const doReferralFilterSet = (filters) => ({
  type: 'SET_REFERRAL_FILTERS',
  payload: filters,
});

export const doAcceptReferralConditions = (referralId) => ({
  type: 'ACCEPT_REFERRAL_CONDITIONS',
  payload: { referralId },
});

const initialState = {
  referralsList: [],
  referralDetails: null,
  filters: {
    withAlertOnly: false,
    stateIds: [
      `${ReferralConstants.ReferralStates.NEW.stateId}`,
      `${ReferralConstants.ReferralStates.CHATTING.stateId}`,
      `${ReferralConstants.ReferralStates.INTERVIEWING.stateId}`,
    ],
  },
};

export const referralsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INSERT_REFERRALS':
      return {
        ...state,
        referralsList: action.payload,
      };
    case 'INSERT_REFERRAL_DETAILS':
      return {
        ...state,
        referralDetails: action.payload,
      };
    case 'DELETE_REFERRAL_DETAILS':
      return {
        ...state,
        referralDetails: null,
      };
    case 'SET_REFERRAL_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case 'ACCEPT_REFERRAL_CONDITIONS': {
      const aux = [...state.referralsList];
      const ix = aux.findIndex((item) => item.referral.id === action.payload.referralId);
      if (ix >= 0) aux[ix].conditionsAcceptedBySchool = 1;
      return {
        ...state,
        referralsList: aux,
      };
    }
    default:
      return state;
  }
};
