import FileSaver from 'file-saver';
import XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UT ';
const filename = `vacanted-report-${new Date().toISOString()}`;
const fileExtension = '.xlsx';

function exportToExcel(apiData) {
  const wscols = [];
  const keys = Object.keys(apiData[0]);
  keys.map((col) => wscols.push({ wpx: Math.max(col.length * 5.5 + 2) }));
  const worksheet = XLSX.utils.json_to_sheet(apiData);

  worksheet['!rows'] = [{ hpt: 24 }];
  worksheet['!cols'] = wscols;
  const newWorkbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'report');
  const excelBuffer = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
  const datatype = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(datatype, filename + fileExtension);
}

export default exportToExcel;
