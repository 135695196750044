import React, { useState, useEffect } from 'react';

import ReactPlayer from 'react-player';

import getUserData from 'hooks/getUserData';
import PaidPlans from 'components/PaidPlans';
import generateRandomString from 'dist/Utils/stringManipulation';
import plansDiscription from '../../dist/Utils/plansDiscription.json';

import styles from './styles.module.css';

export default function Profile() {
  const [useUserData, setUserData] = useState();
  const video = 'https://www.youtube.com/watch?v=R1xgKf0_OKw';

  useEffect(async () => {
    const userData = await getUserData();
    setUserData(userData?.data?.user);
  }, []);

  return (
    <div className={styles['profile-content']}>
      <div className={styles['profile-container']}>
        <h1>Perfil de la Cuenta</h1>
      </div>
      <hr />
      <div className={styles.playerContainer}>
        <ReactPlayer
          url={video}
          className={styles.player}
          muted
          loop
          playing
          controls
        />
      </div>
      <hr />
      <div className={styles.plans}>
        <h1>PLANES DE SUSCRIPCIÓN</h1>
        <div className={styles['plans-container']}>
          {
            plansDiscription.map((plan) => (
              <PaidPlans
                isActive={useUserData?.active}
                key={generateRandomString()}
                plan={plan}
              />
            ))
          }
        </div>
      </div>
      <div className={styles['card-footer']}>
        <p>
          ¿Tiene dudas? Solicite más información a
          {' '}
          <a href="mailto:info.centros@vacanted.com">info.centros@vacanted.com</a>
        </p>
      </div>
    </div>
  );
}
