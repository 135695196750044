import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReferralsTableContext from '../contexts/ReferralsTableContext';
import {
  AlertCell, Cell, CreatedAtCell, NameCell, ReferralCell, StateCell, ViewCell,
} from './cells';

const CellComponents = {
  stateId: StateCell,
  name: NameCell,
  createdAt: CreatedAtCell,
  alert: AlertCell,
  view: ViewCell,
  referral: ReferralCell,
};

const ReferralsDesktopRow = ({ row, fields }) => {
  const { selected, onSelect } = useContext(ReferralsTableContext);
  const classNameRow = selected === row.id ? 'selected-row' : '';

  return (
    <tr className={classNameRow} onClick={() => onSelect(row.referral.id)}>
      {
      fields && fields.map((field) => {
        const isViewCell = field.id === 'view';
        const value = isViewCell ? row.referral.id : row[field.id];
        const CellComponent = CellComponents[field.id] || Cell;

        return (<CellComponent key={`${row.id}_${field.id}`} value={value} rowId={row.id} />);
      })
    }
    </tr>
  );
};

ReferralsDesktopRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    referral: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

export default ReferralsDesktopRow;
