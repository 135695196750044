import axios from 'axios';
import { getUrl } from 'Router/urlConfig';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';

export async function getReferralsFromSchoolId(schoolId, stateIds, withAlertOnly) {
  const { token } = await getDataFromLocalStorage('vacanted');

  return axios.get(`${getUrl('api')}/school/${schoolId}/referrals`, {
    headers: {
      authorization: token,
    },
    params: {
      state_ids: stateIds?.join(','),
      with_alert_only: withAlertOnly,
    },
  })
    .then((resp) => resp.data)
    .catch((error) => error);
}

export async function getReferralFromSchoolIdAndReferralId(schoolId, referralId) {
  const { token } = await getDataFromLocalStorage('vacanted');

  return axios.get(`${getUrl('api')}/school/${schoolId}/referrals/${referralId}`, {
    headers: {
      authorization: token,
    },
  })
    .then((resp) => resp.data)
    .catch((error) => error);
}

export async function updateReferralBySchoolIdAndReferralId(schoolId, referralId, update) {
  const { token } = await getDataFromLocalStorage('vacanted');

  return axios.put(`${getUrl('api')}/school/${schoolId}/referrals/${referralId}`, update, {
    headers: {
      authorization: token,
    },
  })
    .then((resp) => resp.data)
    .catch((error) => error);
}

export async function finishReferralBySchoolIdAndReferralId(schoolId, referralId, finalStateId) {
  const { token } = await getDataFromLocalStorage('vacanted');

  return axios.post(`${getUrl('api')}/school/${schoolId}/referrals/${referralId}/finish`,
    { state_id: finalStateId },
    {
      headers: {
        authorization: token,
      },
    })
    .then((resp) => resp.data)
    .catch((error) => error);
}

export async function reactivateReferralBySchoolIdAndReferralId(schoolId, referralId) {
  const { token } = await getDataFromLocalStorage('vacanted');

  return axios.post(`${getUrl('api')}/school/${schoolId}/referrals/${referralId}/reactivate`,
    { },
    {
      headers: {
        authorization: token,
      },
    })
    .then((resp) => resp.data)
    .catch((error) => error);
}
