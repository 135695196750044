import { useEffect, useRef } from 'react';

const useOutsideClick = (callback) => {
  const domNodeRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (domNodeRef.current && !domNodeRef.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return domNodeRef;
};

export default useOutsideClick;
