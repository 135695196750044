import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import ReferralsTableContext from './contexts/ReferralsTableContext';
import DesktopTable from './desktop/ReferralsDesktopTable';
import MobileTable from './mobile/Table';

const ReferralsTable = ({
  fields,
  data,
  onSelect,
  selected,
}) => (
  <ReferralsTableContext.Provider value={{
    fields,
    data,
    onSelect,
    selected,
  }}
  >
    {!isMobile && <DesktopTable />}
    {isMobile && <MobileTable />}
  </ReferralsTableContext.Provider>
);

ReferralsTable.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

ReferralsTable.defaultProps = {
  selected: null,
};

export default ReferralsTable;
