const getAffinityType = (affinity) => {
  if (affinity >= 80) {
    return 'success';
  }
  if (affinity >= 60) {
    return 'info';
  }
  return 'warning';
};

export default getAffinityType;
