import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import SelfSpinner from 'components/SelfSpinner';

import styles from './styles.module.css';

export default function AreaConfirmData({ capturingData, action, cancel }) {
  if (capturingData?.loading) {
    return (
      <div className={styles.actionContent}>
        <SelfSpinner />
      </div>
    );
  }

  return (
    <div className={styles.actionContent} style={{ display: capturingData?.saveInProgress ? 'flex' : 'none' }}>
      <Button className={styles.cancel} onClick={cancel} variant="light">
        Cancelar
      </Button>

      <Button className={styles.confirm} onClick={action}>
        Confirmar
      </Button>
    </div>
  );
}

AreaConfirmData.propTypes = {
  capturingData: PropTypes.object,
  action: PropTypes.func,
  cancel: PropTypes.func,
}.isRequired;
