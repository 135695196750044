import axios from 'axios';
import { getUrl } from 'Router/urlConfig';

const baseURL = getUrl('api');

export default async function getDataOptions() {
  const publicOptions = await axios.get(`${baseURL}/public`)
    .then((resp) => (resp.data))
    .catch((err) => err);

  const urbanOptions = await axios.get(`${baseURL}/urban`)
    .then((resp) => (resp.data))
    .catch((err) => err);

  const costOptions = await axios.get(`${baseURL}/cost`)
    .then((resp) => (resp.data))
    .catch((err) => err);

  const genreOptions = await axios.get(`${baseURL}/genre`)
    .then((resp) => (resp.data))
    .catch((err) => err);

  const activitiesOptions = await axios.get(`${baseURL}/activities/category`)
    .then((resp) => (resp.data))
    .catch((err) => err);

  const languagesOptions = await axios.get(`${baseURL}/language`)
    .then((resp) => resp.data)
    .catch((err) => err);

  const religionOptions = await axios.get(`${baseURL}/religion`)
    .then((resp) => resp.data)
    .catch((err) => err);

  return ({
    publicOptions,
    urbanOptions,
    costOptions,
    genreOptions,
    activitiesOptions,
    languagesOptions,
    religionOptions,
  });
}
