import FileSaver from 'file-saver';
import XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UT ';
const fileExtension = '.xlsx';

function exportToExcel(data, school) {
  const newWorkbook = XLSX.utils.book_new();
  const { vacantests } = data;

  let vacantestsWorksheet = XLSX.utils.json_to_sheet([]);
  if (vacantests !== null) {
    const wscols = [];
    if (vacantests.length > 0) {
      const keys = Object.keys(vacantests[0]);
      const values = Object.values(vacantests[0]);
      keys.map((col, ix) => wscols.push({
        wpx: Math.max(col.length * 6 + 2, values[ix].length ? values[ix].length * 6 + 2 : 0),
      }));
      vacantestsWorksheet = XLSX.utils.json_to_sheet(vacantests);
      vacantestsWorksheet['!rows'] = [{ hpx: 24 }];
      vacantestsWorksheet['!cols'] = wscols;
    }
    XLSX.utils.book_append_sheet(newWorkbook, vacantestsWorksheet, `Tests - ID ${school.id}`);
  }

  const excelBuffer = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
  const datatype = new Blob([excelBuffer], { type: fileType });
  const fileName = `${school.name.split(' ').join('')}${new Date().toISOString()}.${fileExtension}`;
  FileSaver.saveAs(datatype, fileName + fileExtension);
}

export default exportToExcel;
