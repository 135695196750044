/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  getFirestore, doc, getDoc, onSnapshot, collection, getDocs, where, query,
  // connectFirestoreEmulator,
} from 'firebase/firestore';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import 'react-chat-elements/dist/main.css';
import './chat.scss';
import { ChatItem } from 'react-chat-elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import countryService from '../../Services/countryService';
import Conversation from '../../components/Chat/Conversation';
import dateToMoment from '../../dist/Utils/dateToMoment';
import chatUtils from '../../components/Chat/utils';
import useFirebase from '../../components/Chat/useFirebase';

const db = getFirestore();
// connectFirestoreEmulator(db, 'localhost', 5003);

const chatRedirectRegex = /\?chat_id=(.*)/;

const Chats = ({ userRole }) => {
  const { token } = getDataFromLocalStorage('vacanted');
  const countryId = countryService.getCurrentCountryId();

  const [isLoading, setIsLoading] = useState(true);
  const { firebaseInfo } = useFirebase();
  const [chats, setChats] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [chatIdToRedirect, setChatIdToRedirect] = useState('');
  const [snapshot, setSnapshot] = useState();

  const subscribeToChat = (chatId) => {
    const chatRef = doc(db, 'chats', chatId);
    onSnapshot(chatRef, (snap) => {
      setSnapshot(snap);
    });
  };

  const findMemberChatIds = async () => {
    const memberRef = doc(db, 'members', `${firebaseInfo.id}`);
    const docSnap = await getDoc(memberRef);
    if (docSnap.exists()) {
      const { chats: chatIds } = docSnap.data();
      return chatIds || {};
    }
    return null;
  };

  const findAllChatIds = async () => {
    const back15Days = new Date();
    back15Days.setDate(back15Days.getDate() - 10);
    const q = query(collection(db, 'chats'), where('updatedAt', '>=', back15Days.getTime()));
    const querySnapshot = await getDocs(q);
    const chatIdsToCountryIdMapping = {};
    querySnapshot.forEach((snap) => {
      chatIdsToCountryIdMapping[snap.id] = { countryId: snap.data().countryId };
    });
    return chatIdsToCountryIdMapping;
  };

  useEffect(async () => {
    if (!firebaseInfo) return;
    try {
      const chatIds = chatUtils.isAdmin(userRole) ? await findAllChatIds()
        : await findMemberChatIds();

      if (window.location.search) {
        const results = chatRedirectRegex.exec(window.location.search);
        if (results && results.length >= 2) {
          const chatIdToOpen = results[1];
          const existingChat = Object.keys(chatIds || {}).some(
            (chatId) => chatId === chatIdToOpen,
          );
          if (existingChat) {
            setChatIdToRedirect(chatIdToOpen);
          }
        }
      }

      if (chatIds !== null) {
        Object.keys(chatIds)
          .filter((chatId) => chatIds[chatId].countryId === countryId)
          .forEach((chatId) => {
            subscribeToChat(chatId);
          });
      } else {
        chatUtils.saveErrorInFirebase(db, firebaseInfo.id, `Member does not exist in firebase ${firebaseInfo.id}`);
      }

      setIsLoading(false);
    } catch (error) {
      chatUtils.saveErrorInFirebase(db, null, `Failed to init chat view ${token}`, error);
    }
  }, [firebaseInfo]);

  const chatSortFunction = (a, b) => {
    const aLastMessage = a.messages?.length ? a.messages.slice(-1)[0] : null;
    const bLastMessage = b.messages?.length ? b.messages.slice(-1)[0] : null;

    if (!aLastMessage && !bLastMessage) return 0;
    if (!aLastMessage) return 1;
    if (!bLastMessage) return -1;

    const { timestamp: aTimestamp } = aLastMessage;
    const { timestamp: bTimestamp } = bLastMessage;

    return bTimestamp - aTimestamp;
  };

  const orderChats = (chatsToOrder) => chatsToOrder.sort(chatSortFunction);

  const displayConversation = () => {
    const conversationContainer = document.getElementById('conversation-container');
    if (conversationContainer) {
      conversationContainer.style.display = 'block';
    }
  };

  const updateChats = (newChatSnapshot) => {
    const chatData = newChatSnapshot.data();
    if (!chatData.messages.length && chatData.createdBy !== firebaseInfo.id) return;

    const newChats = chats.filter(({ id }) => id !== newChatSnapshot.id);
    const updatedChat = { ...chatData, id: newChatSnapshot.id };

    if (updatedChat.id === chatIdToRedirect) {
      setSelectedConversationId(updatedChat.id);
      displayConversation();
      setChatIdToRedirect('');
    }
    newChats.push(updatedChat);

    const orderedChats = orderChats(newChats);
    setChats(orderedChats);
  };

  useEffect(() => {
    if (snapshot) updateChats(snapshot);
  }, [snapshot]);

  const handleChatElementClick = async (chatId) => {
    if (chatId === selectedConversationId) return;

    setSelectedConversationId(chatId);
    displayConversation();
  };

  const buildTitleFromOwner = () => {
    if (chatUtils.isAdmin(userRole)) {
      return ' entre familias y centros educativos';
    } if (chatUtils.isFamily(userRole)) {
      return ' con centros educativos';
    } if (chatUtils.isSchool(userRole)) {
      return ' con familias';
    }
    return '';
  };

  const renderChatList = () => {
    if (isLoading) {
      return <FontAwesomeIcon className="fa-spin centered-icon whitest" icon={faSpinner} size="4x" />;
    }
    if (chats.length === 0) {
      return (
        <p className="chat-section-empty">
          No tienes mensajes
          {` ${buildTitleFromOwner()}`}
        </p>
      );
    }
    return (chats.map(({
      id, participants, messages, deleted,
    }) => {
      const lastMessage = messages?.length ? messages.slice(-1)[0] : null;
      const deletedMsg = deleted && deleted[`${lastMessage.timestamp}`];
      const otherParticipantLogo = chatUtils.getOtherParticipantLogo(participants, firebaseInfo.id);
      const otherParticipantNames = chatUtils.getOtherParticipantNamesConcat(
        participants,
        firebaseInfo.id,
      );
      return (
        <ChatItem
          onClick={() => handleChatElementClick(id)}
          key={id}
          chatId={id}
          title={otherParticipantNames}
          avatar={otherParticipantLogo}
          dateString={dateToMoment(lastMessage?.timestamp)}
          subtitle={deletedMsg ? 'Mensaje eliminado' : lastMessage?.text}
          className={`${deletedMsg ? 'deleted ' : ''}${id === selectedConversationId ? 'selectedchat' : ''}`}
          unread={chatUtils.isAdmin(userRole) ? 0 : participants[firebaseInfo.id].unread}
        />
      );
    }));
  };

  const handleBack = () => {
    const conversationContainer = document.getElementById('conversation-container');
    if (conversationContainer) {
      conversationContainer.style.display = 'none';
      setSelectedConversationId(null);
    }
  };

  return (
    <div className="main-chat-container">
      <div className="content-header-container">
        <h3 className="content-header">
          Mensajes
          {` ${buildTitleFromOwner()}`}
        </h3>
      </div>
      <div className="chat-data-container">
        <div className="chat-list-container">
          {/*
          isFamilyRole(role) ?
            <FontAwesomeIcon
              className="new-message-icon fa-solid"
              icon={faPenSquare}
              size="2x"
              onClick={() => {}}
            /> : null
          */}
          {renderChatList()}
        </div>
        {selectedConversationId && (
          <Conversation
            chatId={selectedConversationId}
            handleBack={handleBack}
            userRole={userRole}
          />
        )}
      </div>
    </div>
  );
};

Chats.propTypes = {
  userRole: PropTypes.string,
}.isRequired;

export default Chats;
