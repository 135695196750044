import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import TableReceivedApplications from 'components/TableReceivedApplications';

import { addDays, differenceInDays } from 'date-fns';

import { updateApplicationStatus } from 'hooks/getSchoolApplication';

export default function ActiveReceivedApplications({ data, reloadData, isAdmin }) {
  const [filteredActive, setFilteredActive] = useState(false);

  function calculateTerm(initialDay) {
    const lastDay = addDays(new Date(initialDay), 30);
    return differenceInDays(lastDay, new Date());
  }

  useEffect(() => {
    const definitive = [];
    data.forEach((item) => {
      if (calculateTerm(item.updatedAt) <= 0) {
        updateApplicationStatus(item.id, 7);
      } else {
        definitive.push(item);
      }
    });
    setFilteredActive(definitive);
  }, []);

  return (
    <div>
      <h4>
        Postulaciones recibidas activas
      </h4>
      <p>
        Aquí se muestran las postulaciones que están en proceso.
        Su institución cuenta con 30 días de plazo para descargar el informe de familia.
      </p>
      {filteredActive && <TableReceivedApplications data={filteredActive} type="ActiveReceivedApplications" reloadData={reloadData} isAdmin={isAdmin} />}
    </div>
  );
}

ActiveReceivedApplications.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  reloadData: PropTypes.func,
  isAdmin: PropTypes.bool,
}.isRequired;
