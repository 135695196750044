import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './badge.scss';

const classNames = {
  default: 'badge',
  success: 'badge badge-success',
  primary: 'badge badge-primary',
  light: 'badge badge-light',
  info: 'badge badge-info',
  dark: 'badge badge-dark',
  warning: 'badge badge-warning',
  danger: 'badge badge-danger',
  inverse: 'badge badge-inverse',
  outline: 'badge-outline',
  large: 'badge--large',
};

const Badge = ({
  className, children, type, outline, size,
}) => {
  const _className = classnames(classNames.default, {
    [classNames.outline]: outline,
    [classNames.large]: size === 'large',
  }, classNames[type], className);

  return (
    <span className={_className}>
      {children}
    </span>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'default',
    'success',
    'primary',
    'light',
    'info',
    'dark',
    'warning',
    'danger',
    'inverse',
  ]).isRequired,
  outline: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large']),
};

Badge.defaultProps = {
  className: '',
  outline: false,
  size: 'default',
};

export default Badge;
