import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { LineChart, axisClasses } from '@mui/x-charts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Title from './Title';

export default function Chart({ recommendationsByMonth }) {
  const theme = useTheme();

  const twelveMonths = [];
  const currentMonth = new Date();
  for (let i = 0; i < 12; i += 1) {
    twelveMonths.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - i, 1));
  }

  // Initialize an object to store the totals
  const monthlyTotals = {};
  recommendationsByMonth.forEach(({ month, total }) => {
    monthlyTotals[month] = total;
  });

  // Generate the totals for the last 12 months
  const lastTwelveMonthsTotals = twelveMonths.reverse().map((month) => {
    const formattedMonth = `${month.getFullYear()}-${(month.getMonth() + 1).toString().padStart(2, '0')}`;
    return {
      month: formattedMonth,
      amount: monthlyTotals[formattedMonth] || 0,
    };
  });
  const max = Math.max(...lastTwelveMonthsTotals.map((item) => item.amount));

  return (
    <>
      <Title>
        Recomendaciones a familias
        <Tooltip title="Cantidad de veces que su centro fue recomendado a familias en sus búsquedas" arrow>
          <InfoOutlinedIcon sx={{ cursor: 'pointer', ml: 1 }} />
        </Tooltip>
      </Title>
      <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
        <LineChart
          dataset={lastTwelveMonthsTotals}
          margin={{
            top: 16,
            right: 40,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: 'point',
              dataKey: 'month',
              tickNumber: 2,
              tickLabelStyle: theme.typography.body2,
            },
          ]}
          yAxis={[
            {
              // label: 'Familias',
              labelStyle: {
                ...theme.typography.body1,
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2,
              max: Math.ceil(max * 1.2),
              tickNumber: 3,
            },
          ]}
          series={[
            {
              dataKey: 'amount',
              showMark: false,
              color: theme.palette.primary.light,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
            [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-25px)',
            },
          }}
        />
      </div>
    </>
  );
}

Chart.propTypes = {
  recommendationsByMonth: PropTypes.arrayOf(PropTypes.shape({
    month: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
  })).isRequired,
};
