import { removeDataInLocalStorage, getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import { getUrl } from 'Router/urlConfig';
import api from 'Services/api';

const appURL = getUrl('app');

const clearLogin = async (setUserData) => {
  setUserData({ loginStatus: false, data: [] });
  removeDataInLocalStorage('vacanted');
  setTimeout(() => window.location.replace(appURL), 2000);
};

const checkLoginStatus = async (setUserData) => {
  const userInLocalStorage = getDataFromLocalStorage('vacanted');

  if (userInLocalStorage) {
    const { token, user, routes } = userInLocalStorage;
    const { email } = user;

    const data = { email };

    const headers = {
      headers: {
        authorization: token,
      },
    };

    await api.post('/user/auth', data, headers)
      .then((response) => {
        const familyData = {
          routes,
          user,
        };

        if (response?.status === 202) setUserData({ loginStatus: true, data: familyData });
      })
      .catch(() => clearLogin(setUserData));

    return true;
  }

  return clearLogin(setUserData);
};

export default checkLoginStatus;
