/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import pdfGenerator from 'components/FamilyInfoPDF';
import { format, addDays, differenceInDays } from 'date-fns';
import { es } from 'date-fns/esm/locale';
import { Button } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { applicationsOptions, updateApplicationStatus } from 'hooks/getSchoolApplication';

import RowWithSelect from 'components/RowWithSelect';
import generateRandomString from 'dist/Utils/stringManipulation';

import styles from './styles.module.css';
import pdfPostulation from '../PdfPostulation';

function TableReceivedApplications({
  data, type, reloadData, isAdmin,
}) {
  const [dropdownStatus, setDropdownStatus] = useState();

  function calculateTerm(initialDay) {
    const lastDay = addDays(new Date(initialDay), 30);
    const deadline = differenceInDays(lastDay, new Date());
    return deadline;
  }

  function renderStatus(postulation) {
    if (postulation.id === 3 || postulation.id === 6 || postulation.id === 7) return (<td style={{ color: '#dc3545' }}>{postulation.name}</td>);

    return (<td style={{ color: '#198754' }}>{postulation.name}</td>);
  }

  async function familyReportDownload(postulation) {
    if (Object.keys(postulation.report).length > 0) pdfGenerator(postulation);
  }

  useEffect(async () => {
    const options = await applicationsOptions();

    const studyApplications = options.filter(
      (option) => (
        option.id !== 1
        && option.id !== 2
        && option.id !== 7
        && option.id !== 3
        && option.id !== 4),
    );

    const interviewProcess = options.filter(
      (option) => (option.id !== 1 && option.id !== 2 && option.id !== 4 && option.id !== 7),
    );

    setDropdownStatus({ studyApplications, interviewProcess });
  }, []);

  if (!dropdownStatus) return <p>loading...</p>;

  if (!data) {
    return (
      <>
        <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']}`}>
          <Table responsive>
            <thead>
              <tr>
                <th>Fecha de postulación</th>
                <th>Usuario</th>
                <th>Informe de Familia</th>
                <th>Postulación</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr key={generateRandomString()}>
                <td>{' - '}</td>
                <td>{' - '}</td>
                <td>{' - '}</td>
                <td>{' - '}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  if (type === 'DisabledApplications') {
    return (
      <>
        <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']}`}>
          <Table responsive>
            <thead>
              <tr>
                <th>Fecha de postulación</th>
                <th>Usuario</th>
                <th>Informe de Familia</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {
              data.map((postulation) => (
                // eslint-disable-next-line react/no-unknown-property
                <tr key={generateRandomString()} postulationid={postulation.id}>
                  <td>{format(new Date(postulation.createdAt), "dd 'de' MMMM, yyyy - HH:mm:ss", { locale: es })}</td>
                  <td>{' '}</td>
                  <td>{' '}</td>
                  <td>{' '}</td>
                </tr>
              ))
            }
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  if (type === 'ApplicationsImpossible') {
    return (
      <>
        <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']} ${styles.ApplicationsImpossible} `}>
          <Table responsive>
            <thead>
              <tr>
                <th>Fecha de postulación</th>
              </tr>
            </thead>
            <tbody>
              {
              data.map((postulation) => (
                <tr key={generateRandomString()} postulationid={postulation.id}>
                  <td>{format(new Date(postulation.createdAt), "dd 'de' MMMM, yyyy - HH:mm:ss", { locale: es })}</td>
                </tr>
              ))
            }
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  if (type === 'ActiveReceivedApplications') {
    return (
      <>
        <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']}`}>
          <Table responsive>
            <thead>
              <tr>
                <th>Fecha de postulación</th>
                <th>Usuario</th>
                <th>Informe de Familia</th>
                <th>Postulación</th>
                <th>Plazo disponible para descargar</th>
              </tr>
            </thead>
            <tbody>
              {
              data.map((postulation) => (
                <tr key={generateRandomString()}>
                  <td>{format(new Date(postulation.createdAt), "dd 'de' MMMM, yyyy - HH:mm:ss", { locale: es })}</td>
                  <td>{`${postulation.user.email || '-'}`}</td>
                  <td>

                    <Button
                      onClick={() => {
                        if (!isAdmin) {
                          updateApplicationStatus(postulation.id, 2)
                            .then(() => setTimeout(() => reloadData(), 2000));
                          toast('Postulación enviada a Postulaciones en estudio');
                        }
                        familyReportDownload(postulation);
                      }}
                    >
                      Descargar
                    </Button>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      limit={1}
                    />

                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        if (!isAdmin) {
                          updateApplicationStatus(postulation.id, 2)
                            .then(() => setTimeout(() => reloadData(), 2000));
                          toast('Postulación enviada a Postulaciones en estudio');
                        }
                        pdfPostulation(postulation);
                      }}
                    >
                      Descargar
                    </Button>
                  </td>
                  <td>
                    {`Quedan ${calculateTerm(new Date(postulation.updatedAt))} días`}
                  </td>
                </tr>
              ))
            }
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  if (type === 'ApplicationsInterview') {
    return (
      <>
        <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']}`}>
          <Table responsive>
            <thead>
              <tr>
                <th>Fecha de postulación</th>
                <th>Usuario</th>
                <th>Informe de Familia</th>
                <th>Postulación</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {
              data.map((postulation) => (
                <RowWithSelect
                  key={generateRandomString()}
                  postulationData={postulation}
                  filtersDropdown={dropdownStatus.interviewProcess}
                  reloadData={reloadData}
                  download={() => familyReportDownload(postulation)}
                  postulationDownload={() => pdfPostulation(postulation)}
                />
              ))
            }
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  if (type === 'ApplicationsCompleted') {
    return (
      <>
        <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']}`}>
          <Table responsive>
            <thead>
              <tr>
                <th>Fecha de postulación</th>
                <th>Usuario</th>
                <th>Informe de Familia</th>
                <th>Postulación</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {
                data.map((postulation) => {
                  if (postulation.postulation_status.id === 7) {
                    return (
                      <tr key={generateRandomString()} postulationid={postulation.id}>
                        <td>{format(new Date(postulation.createdAt), "dd 'de' MMMM, yyyy - HH:mm:ss", { locale: es })}</td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        {renderStatus(postulation.postulation_status)}
                      </tr>
                    );
                  }
                  return (
                    <tr key={generateRandomString()} postulationid={postulation.id}>
                      <td>{format(new Date(postulation.createdAt), "dd 'de' MMMM, yyyy - HH:mm:ss", { locale: es })}</td>
                      <td>{`${postulation?.user?.email || '-'}`}</td>
                      <td>
                        <Button
                          onClick={
                            () => familyReportDownload(postulation)
                          }
                        >
                          Descargar
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            pdfPostulation(postulation);
                          }}
                        >
                          Descargar
                        </Button>
                      </td>
                      {renderStatus(postulation.postulation_status)}
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']}`}>
        <Table responsive>
          <thead>
            <tr>
              <th>Fecha de postulación</th>
              <th>Usuario</th>
              <th>Informe de Familia</th>
              <th>Postulación</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((postulation) => (
                <RowWithSelect
                  key={generateRandomString()}
                  postulationData={postulation}
                  filtersDropdown={dropdownStatus.studyApplications}
                  reloadData={reloadData}
                  download={() => familyReportDownload(postulation)}
                  postulationDownload={() => pdfPostulation(postulation)}
                />
              ))
            }
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default TableReceivedApplications;

TableReceivedApplications.propTypes = {
  data: PropTypes.arrayOf([PropTypes.any]).isRequired,
  type: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

TableReceivedApplications.defaultProps = {
  isAdmin: false,
};
