import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Badge from 'components/Badge/Badge';

const actionNames = {
  NEW_CONTACT: 'Responder nueva consulta',
  NEW_TEST: 'Enviar información institucional o descartar',
  NEW_INTERVIEW: 'Coordinar entrevista',
  NEW_POSTULATION: 'Analizar postulación',
  UNREAD_MESSAGES: 'Leer nuevos mensajes',
};

const Alert = ({ alert }) => (
  <div className="referral-row__alert">
    {alert && (
    <Badge type="warning">
      <FontAwesomeIcon icon={faTriangleExclamation} color="#fff" />
      {' '}
      { actionNames[alert] }
    </Badge>
    )}
  </div>
);

Alert.propTypes = {
  alert: PropTypes.string.isRequired,
};

export default Alert;
