import React from 'react';
import PropTypes from 'prop-types';
import '../styles/desktop.scss';

const defaultClassName = 'tab-list';

const TabList = ({ children }) => (
  <ul className={defaultClassName} role="tablist">
    {children}
  </ul>
);

TabList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

TabList.defaultProps = {
  children: [],
};

export default TabList;
