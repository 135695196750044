import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import copyTextToClipboard from 'copy-text-to-clipboard';
import useReferrals from './hooks/useReferrals';
import Spinner from '../../../components/Spinner';
import ReferralsTable from '../../../components/ReferralsTable';
import Filters from './components/filters';
import ReferralDetails from '../../../components/ReferralDetails';
import AcceptReferralDialog from '../../../components/Chat/AcceptReferralDialog';
import SchoolSelectionAdminHeader from '../../../components/SchoolSelectionAdminHeader';

import './styles/referrals.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';

const classNames = {
  main: 'referrals__main',
  content: 'referrals__content',
  emptyState: 'referrals__empty-state',
};

const Referrals = React.memo(() => {
  document.title = 'Familias Referidas - VacantED';
  const history = useHistory();
  const location = useLocation();
  const {
    referrals,
    referralDetails,
    filters,
    fields,
    isLoading,
    acceptReferralDialog,
    onAcceptReferralDialog,
    onSearchReferral,
    onReferralDetailsClose,
    onFilterReferrals,
  } = useReferrals();

  const [showDetails, setShowDetails] = useState(false);

  const filterChangeHandler = (key, value) => {
    const newFilters = { ...filters, [key]: value };

    if (value === '') {
      delete newFilters[key];
    }
    onFilterReferrals(newFilters);
  };

  const selectedHandler = (referralId) => {
    onSearchReferral(referralId);
    setShowDetails(true);
  };

  const searchParamsHandler = () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const referralId = Number(searchParams.get('referralSelected'));
      if (referralId) {
        selectedHandler(referralId);
        document.title = `Familia #${referralId} - VacantED`;
      } else {
        setShowDetails(false);
        document.title = 'Familias Referidas - VacantED';

        if (searchParams.has('states')) {
          const statesToFilter = searchParams.get('states').split(',');
          searchParams.delete('states');
          history.replace({
            search: searchParams.toString(),
          });
          onFilterReferrals({ stateIds: statesToFilter });
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    if (location.search) {
      searchParamsHandler();
    }
  }, [location]);

  const addQueryParam = (referralId) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('referralSelected', referralId);
    history.replace({
      search: searchParams.toString(),
    });
  };

  const removeQueryParam = () => {
    onReferralDetailsClose();
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('referralSelected');
    history.replace({
      search: searchParams.toString(),
    });
  };

  const copyLinkHandler = async () => {
    try {
      copyTextToClipboard(window.location.href);
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <div className={classNames.main}>
      <SchoolSelectionAdminHeader />
      <div className={classNames.content}>
        <Filters
          filters={filters}
          onChange={filterChangeHandler}
          count={referrals?.length}
        />
        {referrals?.length > 0 && (
          <ReferralsTable
            data={referrals}
            fields={fields}
            onSelect={addQueryParam}
          />
        )}
      </div>
      {(referrals?.length === 0 && !isLoading?.list) && (
        <div className={classNames.emptyState}>
          No hay referidos
        </div>
      )}
      {acceptReferralDialog && (
      <AcceptReferralDialog
        familyUserName={acceptReferralDialog?.name}
        callbackWithSelection={onAcceptReferralDialog}
      />
      ) }
      <ReferralDetails
        referral={referralDetails}
        isLoading={isLoading.details}
        show={showDetails}
        onClose={() => removeQueryParam()}
        onCopyLink={copyLinkHandler}
      />

      <Spinner
        fullscreen
        isLoading={isLoading.list}
      />
    </div>
  );
});

export default Referrals;
