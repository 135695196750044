import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../styles/pagination.scss';

const classNames = {
  pagination: 'pagination',
  small: 'pagination--small',
  pageList: 'pagination__list',
  pageItem: 'pagination__item',
  pageDisabled: 'pagination__item--disabled',
  pageSelected: 'pagination__item--selected',
  pagePrevious: 'pagination__item__previous',
  pageNext: 'pagination__item__next',
};

const Previous = ({ pageSelected, onChange }) => (
  <li
    role="menuitem"
    className={classnames(
      classNames.pageItem,
      classNames.pagePrevious, {
        [classNames.pageDisabled]: pageSelected === 1,
      },
    )}
    onClick={() => onChange(pageSelected > 1 ? pageSelected - 1 : pageSelected)}
    onKeyUp={() => () => onChange(pageSelected > 1 ? pageSelected - 1 : pageSelected)}
  >
    Anterior
  </li>
);

const Next = ({ pageSelected, pageQuantity, onChange }) => (
  <li
    role="menuitem"
    className={classnames(
      classNames.pageItem,
      classNames.pageNext, {
        [classNames.pageDisabled]: pageSelected === pageQuantity,
      },
    )}
    onClick={() => onChange(pageSelected < pageQuantity ? pageSelected + 1 : pageSelected)}
    onKeyUp={() => () => onChange(pageSelected > 1 ? pageSelected - 1 : pageSelected)}
  >
    Siguiente
  </li>
);

const buildPages = (pageQuantity, pageSelected, onChange) => {
  const pagesArray = [];
  for (let i = 1; i <= pageQuantity; i += 1) {
    pagesArray.push(i);
  }
  return pagesArray.map((page) => {
    const className = classnames(classNames.pageItem, {
      [classNames.pageSelected]: page === pageSelected,
    });
    return (
      <li
        role="menuitem"
        key={`page_${page}`}
        className={className}
        onClick={() => onChange(page)}
        onKeyUp={() => () => onChange(pageSelected > 1 ? pageSelected - 1 : pageSelected)}
      >
        {page}
      </li>
    );
  });
};

const Pagination = ({
  className,
  size,
  pageQuantity,
  pageSelected,
  onChange,
}) => (
  <div className={classnames(classNames.pagination, className, {
    [classNames.small]: size === 'small',
  })}
  >
    <ul role="menu" className={classNames.pageList}>
      <Previous pageSelected={pageSelected} onChange={onChange} />
      {buildPages(pageQuantity, pageSelected, onChange)}
      <Next pageSelected={pageSelected} pageQuantity={pageQuantity} onChange={onChange} />
    </ul>
  </div>
);

Pagination.propTypes = {
  className: PropTypes.string,
  pageQuantity: PropTypes.number.isRequired,
  pageSelected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['medium', 'small']),
};

Pagination.defaultProps = {
  className: null,
  size: 'medium',
};

Next.propTypes = {
  pageSelected: PropTypes.number.isRequired,
  pageQuantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

Previous.propTypes = {
  pageSelected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
