import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const classNames = {
  info: 'referral-row__info',
  createdAd: 'referral-row__info__created-at',
  name: 'referral-row__info__name',
};

const Info = ({ createdAt, name }) => (
  <div className={classNames.info}>
    <div className={classNames.name}>
      {name}
    </div>
    <div className={classNames.createdAd}>
      {moment(createdAt).format('DD/MM/YYYY')}
    </div>
  </div>
);

Info.propTypes = {
  createdAt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Info;
