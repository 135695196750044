import React from 'react';
import { Button } from 'react-bootstrap';

import { useAdminData } from 'Contexts/AdminContext';
import './styles.module.css';

export default function Header() {
  const { setIsCreating, setIsEditing, isCreating } = useAdminData();

  return (
    <header>
      <h1>Gestion de Usuarios</h1>
      <div>
        <Button
          onClick={() => {
            setIsCreating(!isCreating);
            setIsEditing(false);
          }}
        >
          Agregar usuario
        </Button>
      </div>
    </header>
  );
}
