import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { detailsFields } from 'views/Referrals/src/optionValues';
import getAffinityType from './helpers/getAffinityType';
import getPositionType from './helpers/getPositionType';
import Badge from '../../Badge/Badge';
import Distance from '../../Distance';

import '../styles/desktop.scss';

import 'moment/locale/es';

const classNames = {
  details: 'vacantest-details',
  detailsShow: 'vacantest-details show',
  header: 'vacantest-details__header',
  title: 'vacantest-details__header__title',
  subtitle: 'vacantest-details__header__subtitle',
  content: 'vacantest-details__content',
  group: 'vacantest-details__group',
  groupMerge: 'vacantest-details__group--merge',
  groupMergeHeader: 'vacantest-details__group--merge-header',
  close: 'vacantest-details__header__close',
};

const Vacantest = ({ vacantest }) => (
  <div className={classNames.content}>
    <div className={classNames.groupMergeHeader}>
      <dl>
        <dt>{detailsFields.educLevels}</dt>
        {vacantest.educLevels.map((level) => (
          <dd key={level.name}>{level.name}</dd>
        ))}
      </dl>
      <dl>
        <dt>{detailsFields.educLevelsQty}</dt>
        {vacantest.educLevels.map((level) => (
          <dd key={`${level.name}${level.qty}`}>{level.qty}</dd>
        ))}
      </dl>
      <dl>
        <dt>{detailsFields.startYear}</dt>
        {vacantest.educLevels.map(() => (
          <dd>{vacantest.startYear || '-'}</dd>
        ))}
      </dl>
    </div>
    <hr />
    <div className={classNames.group}>
      <dl>
        <dt>
          <FontAwesomeIcon icon={faCalendarDays} size="sm" />
          {' '}
          {detailsFields.testDate}
        </dt>
        <dd>{moment(vacantest?.testDate)?.format('ll')}</dd>
      </dl>
    </div>
    <div className={classNames.groupMerge}>
      <dl className="middle">
        <dt>{detailsFields.totalAffinity}</dt>
        <dd>
          <Badge type={getAffinityType(vacantest.totalAffinity)}>
            {vacantest.totalAffinity}
            %
          </Badge>
        </dd>
      </dl>
      <dl className="middle">
        <dt>{detailsFields.position}</dt>
        <dd><Badge type={getPositionType(vacantest.position)}>{vacantest.position}</Badge></dd>
      </dl>
    </div>
    <div className={classNames.group}>
      <dl>
        <dt>
          {detailsFields.languages}
        </dt>
        <dd>
          {vacantest.languages.map((level, index) => (
            `${index > 0 ? ', ' : ''}${level.name}`
          ))}
        </dd>
      </dl>
    </div>
    <div className={classNames.groupMergeHeader}>
      <dl className="middle">
        <dt>Presupuesto por niño:</dt>
      </dl>
    </div>
    <div className={classNames.groupMerge}>
      {vacantest.elementarySchoolCost && (
      <dl className="middle">
        <dt>{detailsFields.elementarySchoolCost}</dt>
        {vacantest.elementarySchoolCost.name}
      </dl>
      )}
      {vacantest.highschoolCost && (
      <dl className="middle">
        <dt>{detailsFields.highschoolCost}</dt>
        {vacantest.highschoolCost.name}
      </dl>
      )}
    </div>
    <div className={classNames.group}>
      <dl>
        <dt>{detailsFields.distanceKms}</dt>
        <dd><Distance distance={vacantest.distanceKms} /></dd>
      </dl>
    </div>
  </div>
);

Vacantest.propTypes = {
  vacantest: PropTypes.shape({
    testDate: PropTypes.string,
    startYear: PropTypes.string,
    totalAffinity: PropTypes.number,
    position: PropTypes.number,
    educLevels: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      qty: PropTypes.number,
    })),
    languages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    distanceKms: PropTypes.number,
    elementarySchoolCost: PropTypes.shape({
      name: PropTypes.string,
    }),
    highschoolCost: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default Vacantest;
