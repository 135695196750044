export default [
  {
    id: 'referral',
    name: 'Familia',
  },
  {
    id: 'stateId',
    name: 'Estado',
  },
  {
    id: 'alert',
    name: 'Tareas pendientes',
  },
  {
    id: 'view',
    name: '',
  },
];
