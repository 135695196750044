import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/light-bootstrap-dashboard-pro-react.scss';
import CentroLayout from 'layouts/Centro';
import Blank from 'components/Blank';
import store from '../redux/store';

export default function Router() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/centro" component={CentroLayout} />
          <Route exact path="/:token" component={Blank} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
