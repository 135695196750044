import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useOutsideClick from './useOutsideClick';
import './dropdown-button.scss';

const classNames = {
  container: 'dropdown-button__container',
  button: 'dropdown-button',
  menu: 'dropdown-button__menu',
  item: 'dropdown-button__item',
  default: 'dropdown-button--default',
  primary: 'dropdown-button--primary',
  success: 'dropdown-button--success',
  light: 'dropdown-button--light',
  info: 'dropdown-button--info',
  dark: 'dropdown-button--dark',
  warning: 'dropdown-button--warning',
  danger: 'dropdown-button--danger',
  inverse: 'dropdown-button--inverse',
  outline: 'dropdown-button--outline',
  large: 'dropdown-button--large',

};

const DropdownOrSimpleButton = ({
  className,
  children,
  options,
  type,
  onClick,
  disabled,
  size,
}) => {
  const _className = classnames(className, classNames.button, classNames[type], {
    [classNames.large]: size === 'large',
  });
  const hasDropdown = !!options?.length;
  const [show, setShow] = useState(false);
  const containerRef = useOutsideClick(() => setShow(false));
  const onClickHandler = (id) => {
    setShow(false);
    if (onClick) onClick(id);
  };

  return (
    <div ref={containerRef} className={classNames.container}>
      <button
        disabled={disabled}
        type="button"
        className={_className}
        onClick={hasDropdown ? () => setShow(!show) : onClick}
      >
        {children}
        {' '}
        {hasDropdown && <FontAwesomeIcon style={{ marginLeft: '15px' }} icon={faChevronDown} size="xs" />}
      </button>
      {hasDropdown && show && (
        <ul className={classNames.menu}>
          {options.map((option) => (
            <li
              key={`dropdown-button-${option.id}`}
              role="presentation"
              className={classNames.item}
              onClick={() => onClickHandler(option.id)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

DropdownOrSimpleButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  type: PropTypes.oneOf([
    'primary',
    'success',
    'light',
    'info',
    'dark',
    'warning',
    'danger',
    'inverse',
    'outline',
  ]),
  size: PropTypes.oneOf(['large']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

DropdownOrSimpleButton.defaultProps = {
  className: null,
  children: null,
  options: [],
  type: 'primary',
  onClick: null,
  disabled: false,
  size: null,
};
export default DropdownOrSimpleButton;
