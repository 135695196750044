import React from 'react';
import PropTypes from 'prop-types';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Distance = ({ distance }) => (
  <div>
    <FontAwesomeIcon icon={faLocationDot} color="#ed5565" />
    {' '}
    {distance}
    {' '}
    Kms
  </div>
);

Distance.propTypes = {
  distance: PropTypes.number.isRequired,
};

export default Distance;
