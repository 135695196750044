import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import api from 'Services/api';

export default async function getAllUsers() {
  const { token } = await getDataFromLocalStorage('vacanted');
  const users = await api
    .get('/user/management', {
      headers: {
        authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);

  return users;
}
