import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-chat-elements';

const AcceptReferralDialog = ({
  familyUserName, callbackWithSelection,
}) => {
  const schoolAcceptDialog = {
    show: true,
    header: 'Contacto de usuario de familia',
    headerButtons: [
      {
        type: 'transparent',
        color: 'black',
        text: 'X',
        onClick: () => {
          callbackWithSelection(false);
        },
      },
    ],
    text: `Como institución suscripta, acepto que el usuario de familia ${familyUserName} es referido por VacantED y que en caso de que dicha familia ingrese a nuestro centro educativo se configurará la causa necesaria para la facturación respectiva.`,
    footerButtons: [
      {
        className: 'button-cancel',
        text: 'Aceptar más tarde',
        onClick: () => {
          callbackWithSelection(false);
        },
      },
      {
        className: 'button-ok',
        text: 'Aceptar contacto',
        onClick: () => {
          callbackWithSelection(true);
        },
      },
    ],
  };

  return (
    <>
      <Popup popup={schoolAcceptDialog} />
    </>
  );
};

AcceptReferralDialog.propTypes = {
  familyUserName: PropTypes.string,
  callbackWithSelection: PropTypes.func,
}.isRequired;

export default AcceptReferralDialog;
