import axios from 'axios';
import { getUrl } from 'Router/urlConfig';
import { getDataFromLocalStorage } from '../dist/Storage/localStorageMethods';

const baseURL = getUrl('api');

const getSchoolIdFromStorage = () => {
  const { user } = getDataFromLocalStorage();
  return user?.school_id;
};

export default async function getSchoolInfo(schoolId = null) {
  const schoolData = await axios.get(`${baseURL}/school/${schoolId || getSchoolIdFromStorage()}?complete`)
    .then((resp) => (resp.data))
    .catch((err) => err);

  return schoolData;
}
