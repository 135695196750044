export const yearsValues = {
  THIS_YEAR: 'Este año',
  NEXT_YEAR: 'El año que viene',
  ONLY_EXPLORER: 'Explorando',
};

export const typeValues = {
  PRIVATE: 'Privado',
  PUBLIC: 'Público',
  ONLY_EXPLORER: 'Explorando',
};

export const costValues = {
  0: '$',
  1: '$$',
  2: '$$$',
  3: '$$$$',
};

export const detailsFields = {
  testDate: 'Fecha de creación',
  startYear: 'Año de ingreso',
  email: 'Correo electrónico',
  educLevels: 'Niveles educativos',
  educLevelsQty: 'Cantidad de hijos',
  start_year: 'Para cuándo está buscando',
  totalAffinity: 'Afinidad con el centro educativo',
  position: 'Posición del centro educativo en los resultados',
  distanceKms: 'Distancia estimada',
  elementarySchoolCost: 'Nivel inicial',
  highschoolCost: 'Primaria y secundaria',
  actions: 'Acciones',
  languages: 'Idiomas seleccionados',
};
