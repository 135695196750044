import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabContent } from '../../../Tab';
import Vacantest from '../../../Vacantest';
import Pagination from '../../../Pagination';
import ContactPanel from '../ContactPanel';

const VacantestTab = ({ data, active }) => {
  const { details, chatId, alert } = data;
  const [vacantestSelected, setVacantestSelected] = useState(1);
  const [sendInfoAllowed, setSendInfoAllowed] = useState(!chatId && alert === 'NEW_TEST');

  const handleSendContactResult = (contacted) => {
    if (contacted) {
      setSendInfoAllowed(false);
    }
  };

  return (
    <TabContent active={active}>
      {sendInfoAllowed && <ContactPanel referral={data} callBack={handleSendContactResult} />}
      {details?.tests.length > 1 && (
        <Pagination
          className="vacantests-pagination"
          size="small"
          pageQuantity={details.tests.length}
          pageSelected={vacantestSelected}
          onChange={(page) => setVacantestSelected(page)}
        />
      )}
      {details.tests[(vacantestSelected - 1)] && (
        <Vacantest vacantest={details.tests[(vacantestSelected - 1)]} />
      )}
    </TabContent>
  );
};

VacantestTab.propTypes = {
  data: PropTypes.shape({
    details: PropTypes.shape({
      tests: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    chatId: PropTypes.string,
    alert: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

export default VacantestTab;
