import React from 'react';
import PropTypes from 'prop-types';
import ReferralStateTag from '../../../../ReferralStateTag/ReferralStateTag';

const classNames = {
  state: 'referral-row__state',
  id: 'referral-row__state__referral-id',
};

const State = ({ stateId, referralId }) => (
  <div className={classNames.state}>
    <span className={classNames.id}>
      #
      {referralId}
    </span>
    <ReferralStateTag stateId={stateId} />
  </div>
);

State.propTypes = {
  stateId: PropTypes.number.isRequired,
  referralId: PropTypes.number.isRequired,
};

export default State;
