/* eslint-disable camelcase */
import * as React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

const stateNamesById = {
  1: 'Nuevas',
  2: 'Contactadas',
  4: 'En comunicación',
  6: 'En proceso de entrevistas',
  10: 'Matriculadas',
  11: 'No matriculadas',
  12: 'Descartadas',
};

export default function ReferralsSummary({ referralsByState }) {
  return (
    <>
      <Title>
        Estado de familias referidas
      </Title>
      <Table size="small" sx={{ borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow>
            {Object.values(stateNamesById).map((state_name) => (
              <TableCell key={`header_${state_name}`} align="center">{state_name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {Object.entries(stateNamesById).map(([state_id]) => (
              <TableCell key={`value_${state_id}`} align="center">
                <Link color="primary" href={`/centro/referrals?states=${state_id}`} sx={{ mt: 3 }}>
                  {referralsByState.find(({ state_id: st_id }) => `${st_id}` === state_id)?.total ?? 0}
                </Link>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <Link color="primary" href="/centro/referrals" sx={{ mt: 3 }}>
        Ver familias
      </Link>
    </>
  );
}

ReferralsSummary.propTypes = {
  referralsByState: PropTypes.arrayOf(PropTypes.shape({
    state_id: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  })).isRequired,
};
