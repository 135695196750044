import React from 'react';
import PropTypes from 'prop-types';
import { getSchoolIdFromStorage } from 'dist/Storage/localStorageMethods';
import { TabContent } from '../../../Tab';
import Conversation from '../../../Chat/Conversation';

const MessagesTab = ({ data, active }) => (
  <TabContent active={active}>
    {active && (
    <div className="message-tab">
      <Conversation chatId={data.chatId} userRole={getSchoolIdFromStorage() ? 'school' : 'admin'} refresh={data.refresh} fitParentHeight />
    </div>
    )}
  </TabContent>
);

MessagesTab.propTypes = {
  data: PropTypes.shape({
    chatId: PropTypes.string,
    refresh: PropTypes.func.isRequired,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

export default MessagesTab;
