import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { es } from 'date-fns/esm/locale';
import { Button } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import DropDownSelect from 'components/DropDownSelect';

import { updateApplicationStatus } from 'hooks/getSchoolApplication';

export default function RowWithSelect({
  postulationData,
  filtersDropdown,
  reloadData,
  download,
  postulationDownload,
}) {
  function applicationsToasts(value) {
    if (value.value === 3 || value.value === 5 || value.value === 6) {
      return toast('Postulacion enviada a Postulaciones finalizadas');
    }
    if (value.value === 4) {
      return toast('Postulacion enviada a Postulaciones en proceso de entrevistas');
    }
    return '';
  }

  function setNewPostulationStatus(value) {
    updateApplicationStatus(postulationData.id, value.value)
      .then((resp) => {
        if (resp.message === 'Successfully edited ') {
          return applicationsToasts(value);
        }
        return reloadData();
      })
      .then(() => setTimeout(() => reloadData(), 2000))
      .catch((err) => err);
  }

  return (
    <tr>
      <td>{format(new Date(postulationData.createdAt), "dd 'de' MMMM, yyyy - HH:mm:ss", { locale: es })}</td>
      <td>{`${postulationData?.user?.email || '-'}`}</td>
      <td>
        <Button
          onClick={download}
        >
          Descargar
        </Button>
      </td>
      <td>
        <Button
          onClick={postulationDownload}
        >
          Descargar
        </Button>
      </td>
      <td>
        <div>
          <DropDownSelect
            filters={filtersDropdown}
            pageSelect={{
              label: postulationData?.postulation_status?.name,
              value: postulationData?.postulation_status?.id,
            }}
            setPageSelect={(value) => setNewPostulationStatus(value)}
            type="postulation"
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            limit={1}
          />
        </div>
      </td>
    </tr>
  );
}

RowWithSelect.propTypes = {
  postulationData: PropTypes.arrayOf([PropTypes.any]),
  filtersDropdown: PropTypes.arrayOf(PropTypes.object),
  reloadData: PropTypes.func,
  download: PropTypes.func,
  postulationDownload: PropTypes.string,
}.isRequired;
