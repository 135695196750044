import React, { useContext } from 'react';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VacantestTableContext from '../contexts/ReferralsTableContext';

const ReferralsDesktopHeader = () => {
  const { fields } = useContext(VacantestTableContext);
  return (
    <thead>
      <tr>
        {
          fields && fields.map((field) => (
            <th key={`${field.id}${field.aux}`} className={`heder__${field.id}`}>
              {field.name}
              {field.tooltip && (
                <>
                  {' '}
                  <FontAwesomeIcon icon={faCircleQuestion} color="#3483fa" />
                </>
              )}
            </th>
          ))
        }
      </tr>
    </thead>
  );
};

export default ReferralsDesktopHeader;
