import React, { useContext } from 'react';
import ReferralsTableContext from '../contexts/ReferralsTableContext';
import ReferralRow from './ReferralRow';

import '../../styles/mobile.scss';

const defaultClassName = 'referral-table-mobile';

const MobileTable = () => {
  const { data } = useContext(ReferralsTableContext);
  const renderList = () => {
    if (!data) return null;
    return data.map((row) => (
      <ReferralRow key={`row_${row?.id}`} referral={row} />
    ));
  };

  return (
    <div className={defaultClassName}>
      {renderList()}
    </div>
  );
};

export default MobileTable;
