import api from 'Services/api';
import filterUtils from 'dist/Utils/filters';

export default async function fetchData(filterState, countryId, timePeriods, customRange) {
  const range = filterUtils.getDateValueFromFilter(filterState, timePeriods, customRange);

  const body = {
    properties: 'properties/297474539',
    parameters: {
      dimensions: [
        {
          name: 'eventName',
        },
      ],
      metrics: [
        {
          name: 'eventCount',
        },
      ],
      dateRanges: [range],
    },
    country_id: countryId,
  };
  const datafetched = await api.post('/report', body).then((res) => res.data).catch((err) => err);
  return datafetched;
}

export async function getReportByDateFilterV2(filterState, countryId, timePeriods, customRange) {
  const range = filterUtils.getDateValueFromFilter(filterState, timePeriods, customRange);

  const datafetched = await api.get(`/report/v2?start_date=${range.startDate}&end_date=${range.endDate}`).then((res) => res.data).catch((err) => err);
  return datafetched;
}
