import React from 'react';
import ReferralsDesktopHeader from './ReferralsDesktopHeader';
import ReferralsDesktopBody from './ReferralsDesktopBody';

import '../../styles/desktop.scss';

const defaultClassName = 'referrals-table';

const ReferralsDesktopTable = () => (
  <table className={defaultClassName}>
    <ReferralsDesktopHeader />
    <ReferralsDesktopBody />
  </table>
);

export default ReferralsDesktopTable;
