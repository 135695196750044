import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from 'assets/base64img/logoVacantedColorBase64';

function formatDate(str) {
  const splitDate = str.split(/\D/g);
  return [splitDate[2], splitDate[1], splitDate[0]].join('/');
}

function tableBody(report, columns) {
  const result = [];
  result.push(columns);
  report.forEach((item) => {
    result.push(Object.values(item));
  });
  return result;
}

export default function SchoolReportPDF(reportData, school, selectedDateRange, user) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const { vacantests } = reportData;
  const tableHeader = Object.keys(vacantests[0]).map((column) => ({
    text: column,
    bold: true,
    fontSize: 12,
  }));

  const content = [
    {
      image: logo,
      width: 115,
      alignment: 'center',
      margin: [0, 0, 0, 20],
    },
    {
      text: 'Informe detallado por Centro Educativo',
      style: 'header',
      lineHeight: 1.5,
      alignment: 'center',
      fontSize: 16,
    },
    {
      text: school.name,
      style: 'header',
      bold: true,
      lineHeight: 1.5,
      alignment: 'center',
      fontSize: 16,
    },
    {
      text: `Periodo ${formatDate(selectedDateRange.startDate)} - ${formatDate(selectedDateRange.endDate)}`,
      fontSize: 9,
      alignment: 'center',
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      canvas: [
        {
          type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1,
        },
      ],
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      text: 'Tests de Compatibilidad',
      bold: true,
      lineHeight: 2,
      fontSize: 14,
      alignment: 'center',
    },
    {
      table: {
        headerRows: 1,
        widths: tableHeader.map(() => 'auto'),
        body: tableBody(vacantests, tableHeader),
        alignment: 'center',
      },
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      canvas: [
        {
          type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1,
        },
      ],
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      columns: [
        {
          text: `Reporte generado el ${new Date().toLocaleDateString('es-AR')}`,
          fontSize: 9,
          alignment: 'left',
          lineHeight: 2,
        },
        {
          text: `Reporte generado por usuario ${user.email}`,
          fontSize: 9,
          alignment: 'right',
          lineHeight: 2,
        },
      ],
    },
  ];

  function footer() {
    return [
      {
        text: `© ${new Date().getFullYear()} Vacanted.com`,
        alignment: 'center',
        fontSize: 9,
      },
    ];
  }

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 50, 40, 45],

    content,
    footer,
  };

  pdfMake
    .createPdf(docDefinition)
    .download(`${school.name.split(' ').join('')}${new Date().toISOString()}.pdf`);
}
