import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import '../styles/desktop.scss';

const classNames = {
  tabContent: 'tab-content',
  tabContentActive: 'tab-content--active',
  tabContentBody: 'tab-content__body',
};

const TabContent = ({ active, children }) => {
  const className = classnames(classNames.tabContent, {
    [classNames.tabContentActive]: active,
  });
  return (
    <div className={className} role="tabpanel">
      <div className={classNames.tabContentBody}>
        {children}
      </div>
    </div>
  );
};

TabContent.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TabContent.defaultProps = {
  active: false,
};

export default TabContent;
