import React from 'react';
import PropTypes from 'prop-types';

import DropDownSelect from 'components/DropDownSelect';

import styles from './styles.module.css';

export default function DropDownWithTitle({
  title,
  pageSelect,
  setPageSelect,
  filters,
  isDisabled,
}) {
  return (
    <div className={styles.withDropDownContent}>
      <h3>{title}</h3>
      <DropDownSelect
        pageSelect={pageSelect}
        setPageSelect={setPageSelect}
        filters={filters}
        isDisabled={isDisabled}
      />
    </div>
  );
}

DropDownWithTitle.propTypes = {
  title: PropTypes.string,
  pageSelect: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  setPageSelect: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
}.isRequired;
