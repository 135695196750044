import React, { useEffect, useState } from 'react';
import {
  Card, Col, Form, Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { useAdminData } from 'Contexts/AdminContext';
import { getSchoolIdFromStorage } from 'dist/Storage/localStorageMethods';
import getSchoolsByCountry from 'hooks/getSchoolsByCountry';

function SchoolSelectionAdminHeader() {
  const [isAdmin, setIsAdmin] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolId, setSchoolId] = useState();

  const {
    setSchoolId: setSchoolIdInContext,
  } = useAdminData();

  useEffect(() => {
    const schoolIdFromUser = getSchoolIdFromStorage();
    setIsAdmin(!schoolIdFromUser);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getSchoolsByCountry(selectedCountry).then(setSchoolOptions);
    }
  }, [selectedCountry]);

  useEffect(() => {
    setSchoolIdInContext(schoolId);
  }, [schoolId]);

  const handleSchoolSelectChange = (e) => setSchoolId(e.value.id);

  const handleCountrySelectChange = (e) => setSelectedCountry(e.value);

  if (!isAdmin) return <></>;

  return (
    (
      <>
        <Card style={{ margin: '10px 30px' }}>
          <Row style={{ padding: '10px' }}>
            <Col md="2">
              <Form.Label>País</Form.Label>
              <Select
                placeholder="País"
                options={[{ value: 102, label: 'Argentina' }, { value: 101, label: 'Uruguay' }]}
                name="country"
                onChange={(e) => handleCountrySelectChange(e)}
              />
            </Col>
            <Col md="5">
              <Form.Label>ID VACANTED o Nombre Institución</Form.Label>
              <Select
                placeholder="ID - Nombre"
                options={schoolOptions.map((school) => ({ value: school, label: `${school.id} - ${school.name}` }))}
                name="schoolId"
                onChange={(e) => handleSchoolSelectChange(e)}
              />
            </Col>
          </Row>
        </Card>
      </>
    )
  );
}

export default SchoolSelectionAdminHeader;
