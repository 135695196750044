import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import ReferralDetailsContext from '../contexts/ReferralDetailsContext';
import DetailsPanel from './DetailsPanel';
import ContentPanel from './ContentPanel';
import Header from './Header';

if (isMobile) {
  import('../styles/mobile.scss');
} else {
  import('../styles/desktop.scss');
}

const classNames = {
  detailsModal: 'referral-modal',
  detailsModalShow: 'referral-modal show',
  detailsModalContainer: 'referral-modal__container',
  container: 'referral-details__container',
};

const getInitialTab = (referral) => {
  if (referral?.details?.tests?.length) return 'vacantests';
  return 'messages';
};

const ReferralDetails = ({
  referral, show, onClose, onCopyLink,
}) => {
  if (show) {
    document.body.style.overflow = 'hidden';

    if (referral) {
      const [tabActive, setTabActive] = useState(getInitialTab(referral));
      const [showTooltipCopy, setShowTooltipCopy] = useState(false);

      useEffect(() => {
        setTabActive(getInitialTab(referral));
      }, [referral]);

      const onCopyLinkHandler = () => {
        onCopyLink().then((couldCopy) => {
          if (couldCopy) {
            setShowTooltipCopy(true);
            setTimeout(() => {
              setShowTooltipCopy(false);
            }, 1500);
          }
        });
      };

      return (
        <ReferralDetailsContext.Provider value={referral}>
          <div className={`${show ? classNames.detailsModalShow : classNames.detailsModal}`}>
            <div className={classNames.detailsModalContainer}>
              <Header
                id={referral?.id}
                showTooltipCopy={showTooltipCopy}
                onCopyLinkHandler={onCopyLinkHandler}
                onClose={onClose}
              />
              <div className={classNames.container}>
                <ContentPanel
                  setTabActive={setTabActive}
                  tabActive={tabActive}
                  referral={referral}
                />
                <DetailsPanel referral={referral} />
              </div>
            </div>
          </div>
        </ReferralDetailsContext.Provider>
      );
    }
  }

  document.body.style.overflow = 'auto';
  return null;
};

ReferralDetails.propTypes = {
  referral: PropTypes.shape({
    stateId: PropTypes.number,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  onCopyLink: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

ReferralDetails.defaultProps = {
  referral: null,
};

export default ReferralDetails;
