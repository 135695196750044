import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

import generateRandomString from 'dist/Utils/stringManipulation';

import styles from './styles.module.css';

export default function CheckboxGroup({
  title,
  characteristics,
  setOptionsSelected,
  optionsSelected,
  isDisabled,
  oneChoise,
  type,
}) {
  function handleChange(event) {
    if (oneChoise) {
      return setOptionsSelected({
        [event.target.name]: event.target.checked,
      });
    }

    if (type === 'activities') {
      return setOptionsSelected({
        ...optionsSelected,
        [event.target.name]: event.target.checked,
      });
    }

    return setOptionsSelected({
      ...optionsSelected,
      [event.target.name]: event.target.checked,
    });
  }

  if (type === 'activities') {
    return (
      <div className={`${styles.withDropDownContent} ${styles.replyContainer}`}>
        <h3>{title}</h3>
        {
            characteristics().map((feature) => {
              const { id } = feature;
              const idToString = id.toString();

              return (
                <div key={generateRandomString()}>
                  <Checkbox
                    name={idToString}
                    checked={optionsSelected?.[idToString]}
                    onChange={(event) => handleChange(event)}
                    disabled={isDisabled}
                  />
                  <span>{feature.activity}</span>
                </div>
              );
            })
          }
      </div>
    );
  }

  return (
    <div className={`${styles.withDropDownContent} ${styles.replyContainer}`}>
      <h3>{title}</h3>
      {
          characteristics().map((feature) => (
            <div key={generateRandomString()}>
              <Checkbox
                checked={optionsSelected?.[feature.activity]}
                name={feature.activity}
                onChange={(event) => handleChange((event))}
                disabled={isDisabled}
              />
              <span>{feature.activity}</span>
            </div>
          ))
        }
    </div>
  );
}

CheckboxGroup.propTypes = {
  title: PropTypes.string,
  characteristics: PropTypes.arrayOf(PropTypes.object),
  setOptionsSelected: PropTypes.func,
  optionsSelected: PropTypes.array,
  isDisabled: PropTypes.bool,
  oneChoise: PropTypes.func,
  type: PropTypes.string,
}.isRequired;
