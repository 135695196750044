import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.css';

export default function TitleAndSubtitle({ title, subtitle }) {
  return (
    <div className={styles.title}>
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </div>
  );
}

TitleAndSubtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}.isRequired;
