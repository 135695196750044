/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
// import Dropdown from '../../../../../../components/Dropdown';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ReferralConstants from '../../../../../../constants/referrals';

import '../styles/filters.scss';

const useChips = true;

const statusOptions = [
  {
    state: ReferralConstants.ReferralStates.NEW,
  },
  {
    category: 'En proceso',
  },
  {
    state: ReferralConstants.ReferralStates.APPROACHED,
  },
  {
    state: ReferralConstants.ReferralStates.CHATTING,
  },
  {
    state: ReferralConstants.ReferralStates.INTERVIEWING,
  },
  {
    state: ReferralConstants.ReferralStates.POSTULATED,
  },
  {
    category: 'Finalizadas',
  },
  {
    state: ReferralConstants.ReferralStates.REGISTERED,
  },
  {
    state: ReferralConstants.ReferralStates.NOT_REGISTERED,
  },
  {
    state: ReferralConstants.ReferralStates.DISCARDED,
  },
];

const renderSelectedValues = (values) => values.map(
  (v) => Object.values(ReferralConstants.ReferralStates)
    .find(({ stateId }) => `${stateId}` === v).filterName,
).join(', ');

const classNames = {
  filters: 'vacantests-filters',
  dropdown: 'vacantests-filters__dropdown',
  checkbox: 'vacantests-filters__checkbox',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Filters = ({ filters, onChange, count }) => {
  const theme = useTheme();
  const { stateIds, withAlertOnly } = filters;

  const handlerCheckboxChange = (event) => {
    const { name, checked } = event.target;
    onChange(name, checked);
  };

  const handlerSelectChange = (event) => {
    const { name, value } = event.target;
    const listValue = typeof value === 'string' ? value.split(',') : value;
    onChange(name, listValue);
  };

  function getChipListStyles(name) {
    return {
      fontWeight:
        stateIds?.includes(name)
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <div className={classNames.filters}>
      {/* <Dropdown
        className={classNames.dropdown}
        name="stateId"
        label="Estado"
        options={statusOptions}
        selectedValue={stateId || stateValues.ALL}
        onChange={handlerSelectChange}
      /> */}
      {!useChips && (
      <FormControl className={classNames.dropdown} size="small">
        <InputLabel id="demo-multiple-checkbox-label">Familias</InputLabel>
        <Select
          id="states-multiple-checkbox"
          name="stateIds"
          multiple
          value={stateIds}
          onChange={handlerSelectChange}
          input={<OutlinedInput label="Familias" />}
          renderValue={renderSelectedValues}
          MenuProps={MenuProps}
        >
          {statusOptions.map(({ category, state }) => (
            state ? (
              <MenuItem key={state.filterName} value={`${state.stateId}`}>
                <Checkbox checked={stateIds.includes(`${state.stateId}`)} />
                <ListItemText primary={state.filterName} />
              </MenuItem>
            ) : <ListSubheader key={category}>{category}</ListSubheader>
          ))}
        </Select>
      </FormControl>
      )}
      {useChips && (
        <FormControl className={classNames.dropdown} size="small">
          <InputLabel id="demo-multiple-chip-label">Familias</InputLabel>
          <Select
            id="demo-multiple-chip"
            name="stateIds"
            multiple
            value={stateIds}
            onChange={handlerSelectChange}
            input={<OutlinedInput label="Familias" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((v) => ReferralConstants.stateById(Number(v))?.filterName)
                  .map((value) => (
                    <Chip key={value} label={value} />
                  ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {statusOptions.map(({ category, state }) => (
              state ? (
                <MenuItem
                  key={state.filterName}
                  value={`${state.stateId}`}
                  style={getChipListStyles(`${state.stateId}`)}
                >
                  {state.filterName}
                </MenuItem>
              ) : <ListSubheader key={category}>{category}</ListSubheader>
            ))}
          </Select>
        </FormControl>
      )}
      <div className={classNames.checkbox}>
        <label htmlFor="withAlertOnly">
          <input
            type="checkbox"
            name="withAlertOnly"
            id="withAlertOnly"
            checked={withAlertOnly}
            onChange={handlerCheckboxChange}
          />
          <span>
            <FontAwesomeIcon icon={faTriangleExclamation} color="#f8ac59" />
            {' '}
            Sólo con pendientes
          </span>
        </label>
      </div>
      <div>
        <span>
          <b>
            {count || 0}
            {' '}
            { count === 1 ? 'familia' : 'familias' }
          </b>
        </span>
      </div>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    stateIds: PropTypes.array,
    withAlertOnly: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export default Filters;
