import axios from 'axios';
import { getUrl } from 'Router/urlConfig';

const api = axios.create({
  baseURL: getUrl('api'),
});

api.updateSchool = async (newData, schoolObject, images, school, token) => {
  const data = {
    ...schoolObject,
    ...newData,
  };

  const config = {
    headers: {
      authorization: token,
    },
  };

  const multipart = {
    headers: {
      authorization: token,
      'content-type': 'multipart/form-data',
    },
  };

  if (images.length !== 0) {
    const formData = new FormData();
    formData.filename = 'text';
    formData.append('filename2', 'text');

    return api
      .post(`/school/logo/${school}`, formData, multipart)
      .then((response) => response)
      .catch((err) => err);
  }

  return api
    .put(`/school/schoolupdate/${school}`, data, config);
};

export default api;
