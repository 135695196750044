import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge/Badge';
import ReferralConstants from '../../constants/referrals';

const stateType = (stateId) => {
  switch (stateId) {
    case 1:
      return 'warning';
    case 2:
    case 4:
    case 6:
    case 8:
      return 'info';
    case 10:
      return 'success';
    default:
      return 'default';
  }
};

const getStateName = (aStateId) => Object.values(ReferralConstants.ReferralStates)
  .find(({ stateId }) => stateId === aStateId)?.name;

const ReferralStateTag = ({ stateId }) => (
  <Badge type={stateType(stateId)}>
    {getStateName(stateId)}
  </Badge>
);

ReferralStateTag.propTypes = {
  stateId: PropTypes.number.isRequired,
};

export default ReferralStateTag;
