import axios from 'axios';
import { getUrl } from 'Router/urlConfig';

const apiURL = getUrl('api');

const api = axios.create({ baseURL: apiURL });

// export const apiRegistry = {
//   async post(data) {
//     return api.post('/user/registry', data);
//   },
// };

// const apiLogin = {
//   async post({ email, password }) {
//     return api.post('/login', { email, password });
//   },

//   async postEmail({ email }) {
//     return api.post('/login/email', { email });
//   },

//   async fakeLogin() {
//     return { role: 'ok', token: 'ah' };
//   },

//   async logout(email) {
//     return api.post('/logout', { email });
//   },

// };

// export const apiLoginWithProvider = {
//   async post(token) {
//     return api.post('/login/auth', { idtoken: token });
//   },
// };

export default api;
