import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import getAllUsers from 'hooks/getAllUsers';
import getSchoolsByCity from 'hooks/getSchoolsByCity';
import getRoles from 'hooks/getRoles';

import { useAdminData } from 'Contexts/AdminContext';

import UsersTable from './components/UsersTable';
import UserForm from './components/UserForm';
import FilterUsers from './components/FilterUsers';
import Header from './components/Header';

export default function Admin() {
  const {
    isCreating,
    isEditing,
  } = useAdminData();

  // for now, schools are fecthed by city ID hardcoded for Córdoba
  // PEGAR CIDADES ATIVAS
  const cityId = 1803;

  const [userData, setUserData] = useState([]);
  const [schoolsOptions, setSchoolsOptions] = useState();
  const [roles, setRoles] = useState();

  const [forceUpdate, setForceUpdate] = useState(0);

  async function fetchAndFilterUsers() {
    const fetchedData = await getAllUsers();
    const filteredUsers = fetchedData.filter((user) => user.roleId > 2);
    return setUserData(filteredUsers);
  }

  useEffect(async () => {
    await fetchAndFilterUsers();
    const schoolsByCity = await getSchoolsByCity(cityId);
    const fetchRoles = await getRoles();

    const filteredRoles = fetchRoles.filter((role) => role.id > 2);
    const createSchoolsOptions = schoolsByCity.map((school) => ({ value: school.id, label: `${school.id} - ${school.name}` }));

    setSchoolsOptions(createSchoolsOptions);
    return setRoles(filteredRoles);
  }, []);

  useEffect(async () => {
    if (forceUpdate === 1) {
      await fetchAndFilterUsers();
      setForceUpdate(0);
    } return '';
  }, [forceUpdate]);

  return (
    <Container fluid style={{ height: '100vh' }}>
      <Header />
      <FilterUsers data={userData} />
      { isCreating || isEditing ? <UserForm schoolsOptions={schoolsOptions} roles={roles} setForceUpdate={setForceUpdate} /> : '' }
      <UsersTable data={userData} setForceUpdate={setForceUpdate} />
    </Container>
  );
}
