import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import UserContextProvider from 'Contexts/UserContext';
import AdminContextProvider from 'Contexts/AdminContext';
import 'Services/firebase';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <AdminContextProvider>
        <App />
      </AdminContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
