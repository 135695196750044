import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Row, Col, Card,
} from 'react-bootstrap';

import { useAdminData } from 'Contexts/AdminContext';

export default function FilterUsers({ data }) {
  const {
    filters, setFilters, setFilteredData, setIsFiltering,
  } = useAdminData();

  function handleChange({ target }) {
    const { name, value } = target;
    setFilters({ ...filters, [name]: value });
  }

  function handleFilters() {
    setIsFiltering(true);
    const resultData = data.filter((res) => {
      if (filters.status === '' && filters.schoolName === '' && filters.email === '' && filters.schoolId === '') {
        return res;
      }
      if (filters.status === 'all_admin') {
        return res;
      }
      if (res.active === true && filters.status === 'active') {
        return res;
      }
      if (res.active === false && filters.status === 'inactive') {
        return res;
      }
      if (res.schoolName && filters.schoolName.length > 0) {
        return res.schoolName.toLowerCase().includes(filters.schoolName);
      }
      if (res.email && filters.email.length > 0) {
        return res.email.toLowerCase().includes(filters.email);
      }
      if (res.schoolId && filters.schoolId.length > 0) {
        return res.schoolId.includes(filters.schoolId);
      }
      return '';
    });

    return setFilteredData(resultData);
    // return setFilters(filtersInitialState);
  }

  return (
    <>
      <Card body style={{ marginBottom: '0.5rem' }}>
        <Row style={{ marginBottom: '1rem' }}>
          <Col xs={3}>
            <Form.Group>
              <Form.Label>Buscar por ID Vacanted</Form.Label>
              <Form.Control
                type="number"
                placeholder="ID Vacanted"
                name="schoolId"
                value={filters.schoolId}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Buscar por nombre del Centro</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del Centro"
                name="schoolName"
                value={filters.schoolName}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Busca por e-mail del Admin</Form.Label>
              <Form.Control
                type="email"
                placeholder="E-mail del admin"
                name="email"
                value={filters.email}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Control
                as="select"
                name="status"
                onChange={(e) => handleChange(e)}
              >
                <option value="all_admin">Todos los administradores</option>
                <option value="inactive">Administradores inactivos</option>
                <option value="active">Administradores activos</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col />
          <Col xs={2} style={{ textAlign: 'right' }}>
            <Button onClick={() => handleFilters()}>FILTRAR</Button>
          </Col>
        </Row>
      </Card>
    </>
  );
}

FilterUsers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}.isRequired;
