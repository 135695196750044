import React from 'react';
import PropTypes from 'prop-types';
import './spinner.scss';

const Spinner = ({ fullscreen, isLoading }) => {
  if (isLoading) {
    const spinner = <div className="spinner" />;
    if (fullscreen) {
      return (
        <div className="spinner-container fullscreen">
          {spinner}
        </div>
      );
    }
    return spinner;
  } return null;
};

Spinner.propTypes = {
  fullscreen: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Spinner.defaultProps = {
  fullscreen: false,
  isLoading: false,
};

export default Spinner;
