import { createStore, combineReducers } from 'redux';
import { referralsReducer } from './referrals';

const rootReducer = combineReducers({
  referrals: referralsReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
