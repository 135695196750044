import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../styles/desktop.scss';
import Badge from '../../Badge/Badge';

const classNames = {
  tabItem: 'tab-item',
  tabItemActive: 'tab-item--active',
  tabItemNotify: 'tab-item__notify',
};

const Tab = ({
  active, notify, children, onClick,
}) => {
  const className = classnames(classNames.tabItem, {
    [classNames.tabItemActive]: active,
  });
  return (
    <li
      role="presentation"
      onClick={onClick}
      className={className}
      data-toggle="tab"
    >
      {children}
      {notify && (
        <Badge className={classNames.tabItemNotify} type="warning">
          {notify}
        </Badge>
      )}
    </li>
  );
};

Tab.propTypes = {
  children: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  notify: PropTypes.string,
};

Tab.defaultProps = {
  active: false,
  notify: null,
};

export default Tab;
