import moment from 'moment';

const dateToMoment = (timestamp) => {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const messageDate = moment(date);
  return messageDate.calendar(null, {
    lastDay: '[Ayer]',
    sameDay: 'HH:mm',
    lastWeek: 'D/M',
    sameElse: 'D/M',
  });
};

export default dateToMoment;
