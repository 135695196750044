/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
// import { TiDelete } from 'react-icons/ti';

// import DropDownOptions from 'components/DropDownOptions';
import generateRandomString from 'dist/Utils/stringManipulation';

import styles from './styles.module.scss';
import '../../assets/css/table.css';

function EventsTable({ data, deleteVisit }) {
  const status = [
    {
      name: 'Visitas Activas',
    },
    {
      name: 'Visitas Vencidas',
    },
  ];
  return data ? (
    <>
      <div className={`ReactTable -striped -highlight primary-pagination ${styles['container-table']}`}>
        <Table responsive>
          <thead>
            <tr>
              <th>{status[0].name}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {
              data?.map((calendar, index) => (
                <tr key={generateRandomString()} className={index % 2 === 0 ? '_even' : '_odd'}>
                  <td className={styles.type_column}><p>{calendar.type}</p></td>
                  <td>{calendar.date}</td>
                  {
                    calendar.active === true
                      ? (
                        <td>
                          <button
                            aria-label="close-btn"
                            className={styles.delete_btn}
                            type="button"
                            id="close"
                            onClick={() => deleteVisit(calendar.id)}
                          >
                            <span className={styles.delete_icon}>X</span>
                          </button>
                        </td>
                      )
                      : <td />
                  }

                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </>
  ) : <p>Loading...</p>;
}

export default EventsTable;

EventsTable.propTypes = {
  data: PropTypes.arrayOf([PropTypes.any]),
}.isRequired;
