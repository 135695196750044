import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useLanguages from 'hooks/useLanguages';

import TextAreaWithTitle from 'components/TextAreaWithTitle';
import DropDownSelect from 'components/DropDownSelect';
import AreaConfirmData from 'components/AreaConfirmData';

import styles from './styles.module.css';

export default function Languages({
  infoCentro,
  capturingData,
  setCapturingData,
  languagesOptions,
}) {
  const [characteristics, setCharacteristics] = useState();
  const {
    useLanguage1,
    setUseLanguage1,
    useLanguage2,
    setUseLanguage2,
    useLanguage3,
    setUseLanguage3,
    useLanguage4,
    setUseLanguage4,
    useLanguage5,
    setUseLanguage5,
    useLanguage6,
    setUseLanguage6,
  } = useLanguages(infoCentro);

  function sendData() {
    setCapturingData({
      ...capturingData,
      language: {
        loading: false,
        saveInProgress: false,
      },
    });
  }

  function cancelData() {
    setCapturingData({
      ...capturingData,
      language: {
        saveInProgress: false,
      },
    });
  }

  return (
    <div className={styles.languagesContainer}>
      <TextAreaWithTitle
        title="Descripción acerca de los idiomas"
        placeholder="Ingrese la descripción"
        action={setCharacteristics}
        value={characteristics}
        rewrite={capturingData?.language?.saveInProgress}
      />

      <p>
        Verifique y si es necesario edite las
        lenguas (idiomas) que la institución brinda.
        Usted podrá cargar más idiomas en la sección de Actividades Extracurriculares.
        Máximo 4 lenguas.
      </p>

      <div className={`${styles.languagesSection} ${styles.replyContainer}`}>
        <h4>Lengua primaria</h4>
        <DropDownSelect
          pageSelect={useLanguage1}
          setPageSelect={setUseLanguage1}
          filters={languagesOptions}
          isDisabled={!capturingData?.language?.saveInProgress}
        />
        <DropDownSelect
          pageSelect={useLanguage2}
          setPageSelect={setUseLanguage2}
          filters={languagesOptions}
          isDisabled={!capturingData?.language?.saveInProgress}
        />
        <DropDownSelect
          pageSelect={useLanguage3}
          setPageSelect={setUseLanguage3}
          filters={languagesOptions}
          isDisabled={!capturingData?.language?.saveInProgress}
        />
      </div>
      <div className={`${styles.languagesSection} ${styles.replyContainer}`}>
        <h4>Lengua extranjera</h4>
        <DropDownSelect
          pageSelect={useLanguage4}
          setPageSelect={setUseLanguage4}
          filters={languagesOptions}
          isDisabled={!capturingData?.language?.saveInProgress}
        />
        <DropDownSelect
          pageSelect={useLanguage5}
          setPageSelect={setUseLanguage5}
          filters={languagesOptions}
          isDisabled={!capturingData?.language?.saveInProgress}
        />
        <DropDownSelect
          pageSelect={useLanguage6}
          setPageSelect={setUseLanguage6}
          filters={languagesOptions}
          isDisabled={!capturingData?.language?.saveInProgress}
        />
      </div>

      <AreaConfirmData
        capturingData={capturingData?.language}
        action={() => sendData()}
        cancel={() => cancelData()}
      />
    </div>
  );
}

Languages.propTypes = {
  infoCentro: PropTypes.objectOf(PropTypes.object),
  languagesOptions: PropTypes.arrayOf(PropTypes.object),
  capturingData: PropTypes.object,
  setCapturingData: PropTypes.func,
}.isRequired;
