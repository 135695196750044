import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.css';

export default function TextAreaWithTitle({
  title, value, rewrite, action, placeholder,
}) {
  const [insideState, setInsideState] = useState(value);

  useEffect(() => {
    setInsideState(value);
  }, [value]);

  if (rewrite) {
    return (
      <div className={`${styles.textAreaContainer}`}>
        <h3>{title}</h3>
        <textarea
          placeholder={placeholder}
          type="text-area"
          onBlur={() => action(insideState)}
          onChange={(e) => setInsideState(e.target.value)}
          value={insideState}
        />
      </div>
    );
  }

  return (
    <div className={` ${styles.textAreaContainer}`}>
      <h3>{title}</h3>
      <textarea
        placeholder={placeholder}
        type="text-area"
        value={insideState}
        readOnly
      />
    </div>
  );
}

TextAreaWithTitle.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rewrite: PropTypes.bool,
  action: PropTypes.func,
}.isRequired;
