import React from 'react';
import PropTypes from 'prop-types';

import generateRandomString from 'dist/Utils/stringManipulation';

import { Button } from 'react-bootstrap';

import iconPending from '../../assets/img/icono_pendiente.png';
import iconOK from '../../assets/img/Icono_OK.png';

import styles from './styles.module.css';

export default function PaidPlans({ plan, isActive }) {
  if (plan.characteristicsFamily.length <= 0) {
    return (
      <div className={styles.plan}>
        <h2>{plan.name}</h2>
        <div className={styles.characteristics}>
          <div className={styles.benefits}>
            <h5>Funcionalidades habilitadas para la Institución Educativa:</h5>
            {plan.benefits.map((benefit) => {
              if (benefit.length === 2) {
                const firstString = benefit[0];
                const lastString = benefit[1];

                return (
                  <p key={generateRandomString()}>
                    <b key={generateRandomString()}>{firstString}</b>
                    {lastString}
                  </p>
                );
              }

              return <p key={generateRandomString()}>{benefit}</p>;
            })}
          </div>
        </div>
        <div className={styles['plan-status']}>
          <img src={isActive ? iconOK : iconPending} alt="Logo from status" />
          <p>
            {isActive ? 'ACTIVADO ACCESO' : 'PENDIENTE ACCESO'}
          </p>
          <p>
            PRIVADO GRATUITO
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.plan}>
      <h2>{plan.name}</h2>
      <div className={styles.characteristics}>
        <div className={styles.benefits}>
          <h5>Funcionalidades habilitadas para la Institución Educativa:</h5>
          {plan.benefits.map((benefit) => {
            if (benefit.length === 2) {
              const firstString = benefit[0];
              const lastString = benefit[1];

              return (
                <p key={generateRandomString()}>
                  <b key={generateRandomString()}>{firstString}</b>
                  {lastString}
                </p>
              );
            }

            return <p key={generateRandomString()}>{benefit}</p>;
          })}
        </div>
        <div className={styles.benefits}>
          <h5>Habilita a las familias a:</h5>
          {plan.characteristicsFamily.map(
            (benefit) => <p key={generateRandomString()}>{benefit}</p>,
          )}
        </div>
      </div>
      <div className={styles['plan-status']}>
        <a href="mailto:comercial@vacanted.com">
          <Button type="button">
            CONTACTAR A VACANTED
          </Button>
        </a>
      </div>
    </div>
  );
}

PaidPlans.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string,
    characteristicsFamily: PropTypes.arrayOf(PropTypes.string),
    benefits: PropTypes.arrayOf(PropTypes.string),
  }),
  isActive: PropTypes.bool,
}.isRequired;
