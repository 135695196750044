import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faXmark } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import Button from '../../Button/Button';

const classNames = {
  detailsModalHeader: 'referral-modal__header',
  headerInfo: 'referral-modal__header__info',
  headerInfoDivide: 'referral-modal__header__divide',
  toolButtons: 'referral-modal__header__tools-buttons',
};

const Header = ({
  id, showTooltipCopy, onCopyLinkHandler, onClose,
}) => (
  <div className={classNames.detailsModalHeader}>
    <div className={classNames.headerInfo}>
      <span className={classNames.headerInfo}>Familia</span>
      <span className={classNames.headerInfoDivide}>/</span>
      <span className={classNames.headerInfo}>
        #
        {id}
      </span>
    </div>
    <div className={classNames.toolButtons}>
      <Tippy content="Copiar link">
        <Tippy content=" Copiado! " visible={showTooltipCopy}>
          <Button type="transparent" onClick={onCopyLinkHandler}>
            <FontAwesomeIcon icon={faLink} />
          </Button>
        </Tippy>
      </Tippy>
      <Button type="transparent" onClick={onClose}>
        <FontAwesomeIcon size="lg" icon={faXmark} />
      </Button>
    </div>
  </div>
);

Header.propTypes = {
  id: PropTypes.number.isRequired,
  showTooltipCopy: PropTypes.bool.isRequired,
  onCopyLinkHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Header;
