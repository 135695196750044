import React, { useContext } from 'react';
import ReferralsDesktopRow from './ReferralsDesktopRow';
import ReferralsTableContext from '../contexts/ReferralsTableContext';

const ReferralsDesktopBody = () => {
  const { fields, data } = useContext(ReferralsTableContext);

  return (
    <tbody>
      {
        data && data.map((row) => <ReferralsDesktopRow key={`row_${row.referral.id}`} row={row} fields={fields} />)
      }
    </tbody>
  );
};

export default ReferralsDesktopBody;
