import React from 'react';

// react-bootstrap components
import {
  // Button,
  // Dropdown,
  // Form,
  // InputGroup,
  Navbar,
  // Nav,
  Container,
} from 'react-bootstrap';

import './navbar.css';
// import urlConfig from '../../Router/urlConfig';

function AdminNavbar() {
  // eslint-disable-next-line
  // const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <div className="NavBar__Container">
      <Navbar expand="lg">
        <Container fluid>
          <button
            className="navbar-toggler navbar-toggler-left border-0"
            type="button"
            onClick={() => document.documentElement.classList.toggle('nav-open')}
          >
            <span className="navbar-toggler-bar burger-lines" />
            <span className="navbar-toggler-bar burger-lines" />
            <span className="navbar-toggler-bar burger-lines" />
          </button>
        </Container>
      </Navbar>
    </div>
  );
}

export default AdminNavbar;
