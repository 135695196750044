import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import State from './fields/State';
import Info from './fields/Info';
import Alert from './fields/Alert';
import ReferralsTableContext from '../contexts/ReferralsTableContext';

const classNames = {
  referralRow: 'referral-row-mobile',
  referralRowHeader: 'referral-row-mobile__header',
  referralRowSuccess: 'referral-row-mobile--success',
  referralRowPrimary: 'referral-row-mobile--primary',
  referralRowWarning: 'referral-row-mobile--warning',
  referralRowDisabled: 'referral-row-mobile--disabled',
};

const ReferralRow = ({ referral }) => {
  const { onSelect } = useContext(ReferralsTableContext);
  const className = classnames(classNames.referralRow, {
    [classNames.referralRowWarning]: referral.stateId === 1,
    [classNames.referralRowPrimary]: referral.stateId === 2,
    [classNames.referralRowSuccess]: referral.stateId === 3,
    [classNames.referralRowDisabled]: referral.stateId === 4,
  });

  return (
    <div
      role="presentation"
      className={className}
      onClick={() => onSelect(referral.id)}
    >
      <div className={classNames.referralRowHeader}>
        <State
          stateId={referral.stateId}
          referralId={referral.id}
        />
        <Info
          name={referral.user.email}
          createdAt={referral.createdAt}
        />
      </div>
      <Alert alert={referral.alert} />
    </div>
  );
};

ReferralRow.propTypes = {
  referral: PropTypes.shape({
    stateId: PropTypes.number,
    alert: PropTypes.string,
    id: PropTypes.number,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default ReferralRow;
