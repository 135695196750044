import React from 'react';
import PropTypes from 'prop-types';
import { TabList, Tab } from '../../Tab';
import VacantestTab from './tabs/VacantestTab';
import MessagesTab from './tabs/MessagesTab';

const classNames = {
  content: 'referral-details__content',
  contentPanel: 'referral-details__content-panel',
};

const ContentTabs = ({ tabs, tabActive, setTabActive }) => {
  const filteredTabs = tabs.filter((tab) => tab.data);
  return (
    <>
      <TabList>
        {
          filteredTabs.map((tab) => (
            <Tab
              key={`tabItem-${tab.id}`}
              active={tabActive === tab.id}
              onClick={() => setTabActive(tab.id)}
              notify={tab.notify}
            >
              {tab.name}
            </Tab>
          ))
        }
      </TabList>
      {
          filteredTabs.map((tab) => (
            <tab.Component
              key={`tabContent-${tab.id}`}
              active={tabActive === tab.id}
              data={tab.data}
            />
          ))
        }
    </>
  );
};

ContentTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  details: PropTypes.shape({}),
  tabActive: PropTypes.string.isRequired,
  setTabActive: PropTypes.func.isRequired,
};

ContentTabs.defaultProps = {
  details: null,
};

const ContentPanel = ({ setTabActive, tabActive, referral }) => {
  const { stateId, details, chatId } = referral;
  const tabs = [];

  if (details?.tests?.length) {
    tabs.push({
      id: 'vacantests',
      name: 'Búsquedas',
      alert: null,
      notify: stateId === 1 && !chatId ? details.tests.length : null,
      Component: VacantestTab,
      data: referral,
    });
  }

  if (chatId) {
    tabs.push({
      id: 'messages',
      name: 'Mensajes',
      notify: details?.messages?.unread_count || null,
      Component: MessagesTab,
      data: {
        chatId,
        refresh: referral.refresh,
      },
    });
  }

  return (
    <div className={classNames.content}>
      <div className={classNames.contentPanel}>
        <ContentTabs
          tabs={tabs}
          tabActive={tabActive}
          setTabActive={setTabActive}
        />
      </div>
    </div>
  );
};

ContentPanel.propTypes = {
  setTabActive: PropTypes.func.isRequired,
  tabActive: PropTypes.string.isRequired,
  referral: PropTypes.shape({
    chatId: PropTypes.string,
    stateId: PropTypes.number.isRequired,
    details: PropTypes.shape({
      tests: PropTypes.arrayOf(PropTypes.shape({})),
      messages: PropTypes.shape({
        unread_count: PropTypes.number,
      }),
    }),
    refresh: PropTypes.func.isRequired,
  }).isRequired,
};

export default ContentPanel;
