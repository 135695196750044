import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Button, Card, Form, Row, Col,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import api from 'Services/api';
import { useAdminData } from 'Contexts/AdminContext';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

export default function UserForm({ schoolsOptions, roles, setForceUpdate }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [changePassword, setChangePassword] = useState(true);

  const {
    isEditing,
    setIsEditing,
    isCreating,
    setIsCreating,
    payload,
    setPayload,
    payloadInitialState,
  } = useAdminData();

  function checkIsDisabled() {
    return (Number(payload.role) === 3 || Number(payload.role) === 5 || !payload.role)
      ? setIsDisabled(true)
      : setIsDisabled(false);
  }

  useEffect(() => {
    checkIsDisabled();
  }, [payload.role]);

  function handleChange({ target }) {
    const { name, value } = target;
    return setPayload({ ...payload, [name]: value });
  }

  function handleSelectChange(e) {
    return setPayload({ ...payload, schoolId: e.value });
  }

  async function handleUpdate() {
    const userInLocalStorage = await getDataFromLocalStorage('vacanted');
    const { token } = userInLocalStorage;
    const headers = {
      headers: {
        authorization: token,
      },
    };

    if (!payload.name || !payload.email || !payload.role) {
      setErrorMessage('¡Por favor rellena a todos los campos!');
    } else {
      await api.put('/user/update', {
        id: payload.id,
        data: {
          name: payload.name,
          email: payload.email,
          password: payload.password,
          role_id: parseInt(payload.role, 10),
          school_id: payload.schoolId,
        },
      }, headers).then((res) => res).catch((err) => err);

      setPayload(payloadInitialState);
      setIsEditing(false);
      return setForceUpdate(1);
    } return '';
  }

  function resetState() {
    setPayload(payloadInitialState);
    setIsEditing(false);
    setIsCreating(false);
    return setForceUpdate(1);
  }

  function renderRoles(data) {
    return data.map((role) => (
      <option value={role.id} key={role.name}>{role.name}</option>
    ));
  }

  async function handleSubmit() {
    const userInLocalStorage = await getDataFromLocalStorage('vacanted');
    const { token } = userInLocalStorage;

    const headers = {
      headers: {
        authorization: token,
      },
    };

    const userRoles = ['4', '6', '7'];

    if (!payload.name || !payload.email || !payload.password || !payload.role) {
      setErrorMessage('¡Por favor rellena a todos los campos!');
    } else if (payload.password.length < 4) {
      setErrorMessage('La contraseña debe tener al menos 4 caracteres.');
    } else if (userRoles.includes(payload.role) && !payload.schoolId) {
      setErrorMessage('¡Por favor rellena la institución!');
    } else {
      await api.post('/user/registry', {
        type: 'admin',
        name: payload.name,
        email: payload.email,
        password: payload.password,
        role_id: payload.role,
        school_id: payload.schoolId,
      }, headers).then((res) => res).catch((err) => err);
      toast.success('¡Usuario creado!');
      setPayload(payloadInitialState);
      return setForceUpdate(1);
    } return '';
  }

  function setLabelSchool(userData) {
    if (userData.schoolId) {
      const label = schoolsOptions.filter(
        (school) => school.value === parseInt(payload.schoolId, 10),
      );

      return label[0];
    }

    return { value: '', label: 'Selecione la institución' };
  }

  function renderActions() {
    if (isEditing) {
      return (
        <div className={styles.buttonsContainer}>
          <Button onClick={() => handleUpdate()}>Guardar ediciones</Button>
          <Button onClick={() => resetState()} className={styles.buttonCancel}>Cancelar</Button>
        </div>
      );
    }

    return (
      <div className={styles.buttonsContainer}>
        <Button onClick={() => handleSubmit()}>Guardar usuario</Button>
        <Button onClick={() => resetState()} className={styles.buttonCancel}>Cancelar</Button>
      </div>
    );
  }

  return (
    !roles || !schoolsOptions
      ? 'Loading...'
      : (
        <Card body>
          {isCreating ? <h3>Crear usuario</h3> : ''}
          {isEditing ? <h3>Editar usuario</h3> : ''}
          <Form>
            <Form.Group style={{ marginBottom: '1rem' }}>
              <Row>
                <Col>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre del usuario"
                    name="name"
                    value={payload.name || ''}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
                <Col>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail del usuario"
                    name="email"
                    value={payload.email || ''}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    disabled={isEditing ? changePassword : false}
                    type="password"
                    placeholder="Contraseña"
                    name="password"
                    value={payload.password || ''}
                    onChange={(e) => handleChange(e)}
                  />
                  {
                    isEditing
                      ? (
                        <label htmlFor="change-password">
                          <input
                            id="change-password"
                            value={changePassword || ''}
                            type="checkbox"
                            onClick={() => setChangePassword(!changePassword)}
                          />
                          {' Cambiar contraseña'}
                        </label>
                      )
                      : ''
                  }
                </Col>
                <Col>
                  <Form.Label>Credenciales</Form.Label>
                  <Form.Control
                    as="select"
                    name="role"
                    value={payload.role || 'default'}
                    onChange={(e) => {
                      handleChange(e);
                      checkIsDisabled();
                    }}
                  >
                    <option value="default" disabled>Selecione la credencial</option>
                    {renderRoles(roles)}
                  </Form.Control>
                </Col>
              </Row>
              <Form.Label>ID institución</Form.Label>
              <Select
                isDisabled={isDisabled}
                options={schoolsOptions}
                name="schoolId"
                value={setLabelSchool(payload)}
                onChange={(e) => handleSelectChange(e)}
              />
            </Form.Group>
            <ToastContainer
              position="top-center"
              autoClose={2000}
              newestOnTop={false}
              rtl={false}
              closeOnClick
              hideProgressBar
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {renderActions()}
            <span>
              {errorMessage}
            </span>
          </Form>
        </Card>
      )
  );
}

UserForm.propTypes = {
  schoolsOptions: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
}.isRequired;
