import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';

import generateRandomString from 'dist/Utils/stringManipulation';
import schoolUpdate from 'dist/Collections/schoolUpdate';

import InputTextWithTitle from 'components/InputTextWithTitle';
import TextAreaWithTitle from 'components/TextAreaWithTitle';
import TitleAndSubtitle from 'components/TitleAndSubtitle';
import AreaConfirmdata from 'components/AreaConfirmData';

import styles from './styles.module.css';

export default function BasicInfo({
  infoCentro, capturingData, setCapturingData, setOpen, setAlert,
}) {
  const [images, setImages] = useState([{ data_url: infoCentro?.logo }]);
  const [webSite, setWebSite] = useState(infoCentro?.website);
  const [description, setDescription] = useState(infoCentro?.description);
  const { token } = JSON.parse(localStorage.getItem('vacanted'));

  const onChange = (imageList) => {
    setImages(imageList);
  };

  async function sendData() {
    setCapturingData({
      ...capturingData,
      basicInfos: {
        loading: true,
        saveInProgress: false,
      },
    });

    await schoolUpdate.updateSchool({
      website: webSite,
      description,
    }, infoCentro, '', infoCentro.id, token)
      .then((resp) => {
        if (resp.data.message === 'School updated successfully') {
          setCapturingData({
            ...capturingData,
            basicInfos: {
              loading: false,
              saveInProgress: false,
            },
          });
          setAlert({
            message: '¡Información escolar actualizada!',
            type: 'success',
          });
          setOpen(true);
        }
      })
      .catch((err) => {
        setCapturingData({
          ...capturingData,
          basicInfos: {
            loading: false,
            saveInProgress: false,
          },
        });
        setAlert({
          message: '¡Hubo un error al actualizar la información!',
          type: 'error',
        });
        setOpen(true);
        return err;
      });
  }

  function cancelData() {
    setImages([
      { data_url: infoCentro.logo },
    ]);
    setWebSite(infoCentro.website);
    setDescription(infoCentro.description);

    setCapturingData({
      ...capturingData,
      basicInfos: {
        saveInProgress: false,
      },
    });
  }

  return (
    <div className={styles.basicInfoContainer}>
      <p>Complete o actualice la informacion de su ficha de Institución Educativa</p>
      <InputTextWithTitle title="Nombre de la Institución Educativa" value={infoCentro?.name} />

      <div className={`${styles.LogoContent} ${styles.replyContainer}`}>
        <TitleAndSubtitle
          title="Logo"
          subtitle="Asegúrese de subir una imagen de su logo en buena calidad,
          en formato gif, jpg o png. Tamaño sugerido mínimo: 300x300px"
        />
        <div className={`${styles.areaUpdateLogo}`}>
          <ImageUploading
            multiple={false}
            value={images}
            onChange={onChange}
            maxNumber={64}
            dataURLKey="data_url"
            className={styles['component-img-uploading']}
            acceptType={['jpg', 'png']}
          >
            {({
              imageList,
              onImageUpload,
            }) => (
              <div className={styles['upload__image-wrapper']}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onImageUpload}
                  style={{ display: capturingData?.basicInfos?.saveInProgress ? 'block' : 'none' }}
                >
                  SUBIR LOGO
                </button>
                {imageList.length === 0 ? (<img alt="" src={images[0].data_url} />) : imageList.map((image) => (
                  <div key={generateRandomString()} className={styles['image-item']}>
                    <img src={image.data_url} alt="" width="100" />
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
      </div>
      <hr />
      <div className={`${styles.anotheInfo} ${styles.replyContainer}`}>
        <InputTextWithTitle title="Tipo de Institución Educativa" value={infoCentro.public.name} />
      </div>
      <div className={`${styles.location} ${styles.replyContainer}`}>
        <div className={`${styles.localization} ${styles.replyContainer}`}>
          <h3>Verifique la ubicación de su Centro Educativo </h3>

          <InputTextWithTitle title="Ciudad" value={infoCentro.city.name} />
          <InputTextWithTitle title="Barrio" value={infoCentro.neighborhood.name} />
          <InputTextWithTitle title="Calle" value={infoCentro.address} />

          <div className={`${styles.localization} ${styles.replyContainer}`}>
            <TitleAndSubtitle
              title="¿Son correctos los datos por defecto?"
              subtitle="Si el nombre o la ubicación que Vacanted tiene
                registrado por defecto no es correcta, póngase en contacto con soporte@vacanted.com."
            />
          </div>
          <InputTextWithTitle title="Sitio Web" value={webSite} action={(e) => setWebSite(e.target.value)} rewrite={capturingData?.basicInfos?.saveInProgress} />
        </div>
      </div>
      <TextAreaWithTitle
        title="Descripción"
        value={description}
        placeholder="Ingrese la descripción de su Centro Educativo"
        action={setDescription}
        rewrite={capturingData?.basicInfos?.saveInProgress}
      />
      <AreaConfirmdata
        capturingData={capturingData.basicInfos}
        action={() => sendData()}
        cancel={() => cancelData()}
      />
    </div>
  );
}

BasicInfo.propTypes = {
  infoCentro: PropTypes.objectOf(PropTypes.object),
  capturingData: PropTypes.object,
  setCapturingData: PropTypes.func,
  setOpen: PropTypes.func,
  setAlert: PropTypes.func,
}.isRequired;
