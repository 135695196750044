import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'moment/locale/es';
import Button from 'components/Button/Button';

const classNames = {
  comments: 'referral-comments',
  detailsTitle: 'referral-comments__title',
  list: 'referral-comments__list',
  item: 'referral-comments__item',
  itemDate: 'referral-comments__item__date',
  itemUser: 'referral-comments__item__user',
  itemComment: 'referral-comments__item__comment',
  input: 'referral-comments__input',
  inputContainer: 'referral-comments__input__container',
  button: 'referral-comments__button',
  form: 'referral-comments__form',
  formActive: 'referral-comments__form--active',
  sendButton: 'referral-comments__send-button',
  emptyComments: 'referral-comments__empty-comments',
  systemComment: 'referral-comments__system-comment',
};

const Comment = ({ item }) => {
  const { user, created_at: createdAt, comment } = item;
  return (
    <div className={`${classNames.item} ${user.role === 'system' ? classNames.systemComment : ''}`}>
      <div className={classNames.itemUser}>
        {user.role === 'system' ? 'VacantED' : (user.email ?? user.name)}
      </div>
      <div className={classNames.itemDate}>
        {moment(createdAt).format('lll')}
      </div>
      <div className={classNames.itemComment}>
        {comment}
      </div>
    </div>
  );
};

Comment.propTypes = {
  item: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
    }),
    created_at: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};

const CommentList = ({ comments }) => (
  <div className={classNames.list}>
    {comments?.map((comment) => (
      <Comment key={comment.created_at} item={comment} />
    ))}
    {(!comments || comments?.length === 0) && (
      <div className={classNames.emptyComments}>
        No hay comentarios
      </div>
    )}
  </div>
);

CommentList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    created_at: PropTypes.string,
    comment: PropTypes.string,
  })),
};

CommentList.defaultProps = {
  comments: [],
};

const CommentForm = ({ onSubmit }) => {
  const [value, setValue] = useState('');
  const buttonRef = useRef();
  const onCancel = () => {
    setValue('');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    onSubmit(value);
    setValue('');
  };

  return (
    <form className={value ? classNames.formActive : classNames.form} onSubmit={onSubmitHandler}>
      <div className={classNames.inputContainer}>
        <textarea
          onChange={handleChange}
          className={classNames.input}
          value={value}
          placeholder="Escribe un comentario..."
          rows={5}
          cols={50}
        />
      </div>
      {value && (
        <Button ref={buttonRef} submit type="primary" className={classNames.sendButton}>
          Comentar
        </Button>
      )}
      {value && (
        <Button onClick={onCancel} type="light">
          Cancelar
        </Button>
      )}
    </form>
  );
};

CommentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const CommentPanel = ({ referral }) => {
  const { comments } = referral;
  return (
    <div className={classNames.comments}>
      <div className={classNames.detailsTitle}>
        Observaciones internas
      </div>
      <CommentList comments={comments} />
      <CommentForm onSubmit={referral?.insert?.comment} />
    </div>
  );
};

CommentPanel.propTypes = {
  referral: PropTypes.shape({
    comments: PropTypes.arrayOf(PropTypes.shape({
      user: PropTypes.shape({
        name: PropTypes.string,
      }),
      created_at: PropTypes.string,
      comment: PropTypes.string,
    })),
    insert: PropTypes.shape({
      comment: PropTypes.func,
    }),
  }).isRequired,
};

export default CommentPanel;
