import api from 'Services/api';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';

export default async function getUserData() {
  const userInLocalStorage = await getDataFromLocalStorage('vacanted');
  const { token } = userInLocalStorage;

  const headers = {
    headers: {
      authorization: token,
    },
  };

  const userData = await api.post('/login/panel', {}, headers)
    .then((response) => ({ loginStatus: true, data: response?.data }))
    .catch((err) => err);

  return userData;
}
