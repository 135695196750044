import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useUserData } from 'Contexts/UserContext';
import checkLoginStatus from 'dist/Utils/checkLoginStatus';
import Sidebar from 'components/Sidebar/Sidebar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import sidebarBackgroundImage from 'assets/img/Area_privada_familia_back_izq.jpg';
// dinamically create dashboard routes
import generateRandomString from 'dist/Utils/stringManipulation';

import ReportBySchool from 'views/Admin/ReportBySchool';
import VacantestsReportBySchool from 'views/Admin/VacantestsReportBySchool';
import InternalReports from 'views/Admin/InternalReports';
import UsersManagement from 'views/Admin/UsersManagement';

import Profile from 'views/Profile';
import SearchesResults from 'views/SearchesResults';
import EditRecord from 'views/EditRecord';
import Referrals from 'views/Referrals';
import Favorites from 'views/Favorites';
import SchoolEvents from 'views/SchoolEvents';
import ReceivedApplications from 'views/ReceivedApplications';
import Chats from 'views/Chats';
import SchoolDashboard from 'views/SchoolDashboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.css';

function Centro() {
  const { userData, setUserData } = useUserData();
  const loggedUser = userData.loginStatus;
  const location = useLocation();

  useEffect(() => {
    checkLoginStatus(setUserData);
  }, []);

  function getViewComponent(routeProp, prop) {
    const componentHash = {
      ReportBySchool: () => <ReportBySchool {...routeProp} />,
      InternalReports: () => <InternalReports {...routeProp} />,
      UsersManagement: () => <UsersManagement {...routeProp} />,
      VacantestsReportBySchool: () => <VacantestsReportBySchool {...routeProp} />,
      Profile: () => <Profile {...routeProp} />,
      Referrals: () => <Referrals {...routeProp} />,
      Favorites: () => <Favorites {...routeProp} />,
      SearchesResults: () => <SearchesResults {...routeProp} />,
      EditRecord: () => <EditRecord {...routeProp} />,
      SchoolEvents: () => <SchoolEvents {...routeProp} />,
      ReceivedApplications: () => <ReceivedApplications {...routeProp} />,
      SchoolChats: () => <Chats userRole="school" {...routeProp} />,
      AdminChats: () => <Chats userRole="admin" {...routeProp} />,
      SchoolDashboard: () => <SchoolDashboard {...routeProp} />,
    };

    return componentHash[prop.component]();
  }

  const getPrivateRoutes = (routeList) => routeList.map((prop, key) => {
    if (prop.collapse) {
      return getPrivateRoutes(prop.views);
    }

    if (prop.layout.match(/centro/)) {
      return (
        <Route
          path={prop.layout + prop.path}
          key={generateRandomString(key)}
          render={(routeProp) => getViewComponent(routeProp, prop)}
        />
      );
    }
    return null;
  });

  const getTitleHeader = (pathname) => {
    const name = pathname.slice(pathname.lastIndexOf('/'));
    const route = userData.data.routes.find((userRoute) => userRoute.path === name);
    return route ? route.name : '';
  };

  return loggedUser ? (
    <>
      <div className={`${styles.wrapper} wrapper`}>
        <Sidebar
          routes={userData.data.routes}
          image={sidebarBackgroundImage}
          background=""
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <div className={styles.header}>
              <h1>{getTitleHeader(location.pathname)}</h1>
            </div>
            <Switch>{getPrivateRoutes(userData.data.routes)}</Switch>
          </div>
          <button
            className={`${styles.hideSidebar} buttonHide`}
            type="button"
            onClick={() => document.documentElement.classList.toggle('nav-close')}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </div>
    </>
  ) : <p>Loading...</p>;
}

export default Centro;
