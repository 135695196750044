import React from 'react';

import PropTypes from 'prop-types';

import TableReceivedApplications from 'components/TableReceivedApplications';

export default function ApplicationsImpossible({ data }) {
  return (
    <div>
      <h4>
        Intentos de Postulaciones recibidas que fueron rechazadas por no contar con la suscripción
      </h4>
      <TableReceivedApplications data={data} type="ApplicationsImpossible" />
    </div>
  );
}

ApplicationsImpossible.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}.isRequired;
