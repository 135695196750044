import {
  formatISO9075, startOfYear, subMonths, subDays,
} from 'date-fns';

const endDate = formatISO9075(new Date(), { representation: 'date' });

const dateRange = {
  allHistory: {
    startDate: '2021-01-01',
    endDate,
  },
  allYear: {
    startDate: formatISO9075(startOfYear(new Date()), { representation: 'date' }),
    endDate,
  },
  lastSixMonths: {
    startDate: formatISO9075(subMonths(new Date(), 6), { representation: 'date' }),
    endDate,
  },
  lastThreeMonths: {
    startDate: formatISO9075(subMonths(new Date(), 3), { representation: 'date' }),
    endDate,
  },
  lastThirtyDays: {
    startDate: formatISO9075(subDays(new Date(), 30), { representation: 'date' }),
    endDate,
  },
};

export default dateRange;
