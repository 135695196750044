import React from 'react';
import PropTypes from 'prop-types';

const NameCell = ({ value }) => (
  <td>
    {value}
  </td>
);

NameCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default NameCell;
