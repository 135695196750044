import React, { useState } from 'react';
import PropTypes from 'prop-types';

import schoolUpdate from 'dist/Collections/schoolUpdate';

import TextAreaWithTitle from 'components/TextAreaWithTitle';
import DropDownWithTitle from 'components/DropDownWithTitle';
import CheckboxGroup from 'components/CheckboxGroup';
import AreaConfirmData from 'components/AreaConfirmData';

import styles from './styles.module.css';

export default function MainFeatures({
  infoCentro,
  educationalManagementOptions,
  typeOfUrbanizationOptions,
  costOptions,
  genreOptions,
  activitiesOptions,
  capturingData,
  setCapturingData,
  setOpen,
  setAlert,
}) {
  const [optionsSelected, setOptionsSelected] = useState();
  const [characteristics, setCharacteristics] = useState(infoCentro?.characteristics?.mainCharacteristics ? infoCentro?.characteristics?.mainCharacteristics : '');

  const [educationManagement, setEducationManagement] = useState({
    value: infoCentro?.public.id ? infoCentro?.public.id : '',
    label: infoCentro?.public.name ? infoCentro?.public.name : 'Seleccione una gestión educativa',
  });
  const [typeOfUrbanization, setTypeOfUrbanization] = useState({
    value: infoCentro?.urban.id ? infoCentro?.urban.id : '',
    label: infoCentro?.urban.name ? infoCentro?.urban.name : 'Seleccione una Urbanización',
  });
  const [cost, setCost] = useState({
    value: infoCentro?.cost.id ? infoCentro?.cost.id : '',
    label: infoCentro?.cost.name ? infoCentro?.cost.name : 'Seleccione el Tipo de Costo',
  });
  const [typeOfInstitution, setTypeOfInstitution] = useState({
    value: infoCentro?.genre.id ? infoCentro?.genre.id : '',
    label: infoCentro?.genre.name ? infoCentro?.genre.name : 'Seleccione el Tipo de Institución',
  });

  const characteristicsOptions = () => {
    const array = activitiesOptions.find((arrayActivity) => arrayActivity.type === 'Características principales');
    return array.activity;
  };

  // const findActivities = () => {
  //   const array = infoCentro?.schoolactivities.filter(
  //     (arrayActivity) => arrayActivity.activity_type_id === 1,
  //   );

  //   return array;
  // };

  // console.log(infoCentro?.schoolactivities, findActivities());

  const { token } = JSON.parse(localStorage.getItem('vacanted'));

  async function sendData() {
    const newData = {
      public_id: educationManagement.value,
      urban_id: typeOfUrbanization.value,
      cost_id: cost.value,
      genre_id: typeOfInstitution.value,
    };

    setCapturingData({
      ...capturingData,
      mainFeature: {
        loading: true,
        saveInProgress: false,
      },
    });

    await schoolUpdate.updateSchool(newData, infoCentro, '', infoCentro.id, token)
      .then((resp) => {
        if (resp.data.message === 'School updated successfully') {
          setCapturingData({
            ...capturingData,
            mainFeature: {
              loading: false,
              saveInProgress: false,
            },
          });
          setAlert({
            message: '¡Información escolar actualizada!',
            type: 'success',
          });
          setOpen(true);
        }
      })
      .catch((err) => {
        setCapturingData({
          ...capturingData,
          mainFeature: {
            loading: false,
            saveInProgress: false,
          },
        });
        setAlert({
          message: '¡Hubo un error al actualizar la información!',
          type: 'error',
        });
        setOpen(true);
        return err;
      });
  }

  function cancelData() {
    // setOptionsSelected(infoCentro?.activities?.mainCharacteristics
    //   ? infoCentro?.activities?.mainCharacteristics
    //   : '');
    setCharacteristics(
      infoCentro?.characteristics?.mainCharacteristics ? infoCentro?.characteristics?.mainCharacteristics : '',
    );
    setEducationManagement({
      value: infoCentro?.public?.id ? infoCentro?.public?.id : '',
      label: infoCentro?.public?.name ? infoCentro?.public?.name : 'Seleccione una gestión educativa',
    });
    setTypeOfUrbanization({
      value: infoCentro?.urban?.id ? infoCentro?.urban?.id : '',
      label: infoCentro?.urban?.name ? infoCentro?.urban?.name : 'Seleccione una Urbanización',
    });
    setCost({
      value: infoCentro?.cost?.id ? infoCentro?.cost?.id : '',
      label: infoCentro?.cost?.name ? infoCentro?.cost?.name : 'Seleccione el Tipo de Costo',
    });
    setTypeOfInstitution({
      value: infoCentro?.genre?.id ? infoCentro?.genre?.id : '',
      label: infoCentro?.genre?.name ? infoCentro?.genre?.name : 'Seleccione el Tipo de Institución',
    });
    setCapturingData({
      ...capturingData,
      mainFeature: {
        saveInProgress: false,
      },
    });
  }

  // useEffect(() => {
  //   let newObj
  //   findActivities().map((activities) => (
  //     newObj = {
  //       ...optionsSelected,
  //       [activities.activity_id]: true,
  //     }
  //     // setOptionsSelected()
  //   ));

  //   console.log(optionsSelected);
  // }, []);

  return (
    <div className={styles.mainFeatures}>
      <TextAreaWithTitle
        title="Descripción de las Características principales"
        placeholder="Ingrese la descripción"
        action={setCharacteristics}
        value={characteristics}
        rewrite={capturingData?.mainFeature?.saveInProgress}
      />
      <DropDownWithTitle
        title="Gestión educativa"
        pageSelect={educationManagement}
        setPageSelect={setEducationManagement}
        filters={educationalManagementOptions}
        isDisabled={!capturingData?.mainFeature?.saveInProgress}
      />
      <DropDownWithTitle
        title="Tipo de Urbanización"
        pageSelect={typeOfUrbanization}
        setPageSelect={setTypeOfUrbanization}
        filters={typeOfUrbanizationOptions}
        isDisabled={!capturingData?.mainFeature?.saveInProgress}
      />
      <DropDownWithTitle
        title="Costo"
        pageSelect={cost}
        setPageSelect={setCost}
        filters={costOptions}
        isDisabled={!capturingData?.mainFeature?.saveInProgress}
      />
      <DropDownWithTitle
        title="Tipo de Institución"
        pageSelect={typeOfInstitution}
        setPageSelect={setTypeOfInstitution}
        filters={genreOptions}
        isDisabled={!capturingData?.mainFeature?.saveInProgress}
      />
      <hr />
      <CheckboxGroup
        title="Seleccione las opciones que apliquen a su Institución"
        characteristics={characteristicsOptions}
        setOptionsSelected={setOptionsSelected}
        optionsSelected={optionsSelected}
        isDisabled={!capturingData?.mainFeature?.saveInProgress}
        type="activities"
      />
      <AreaConfirmData
        capturingData={capturingData?.mainFeature}
        action={() => sendData()}
        cancel={() => cancelData()}
      />
    </div>
  );
}

MainFeatures.propTypes = {
  infoCentro: PropTypes.objectOf(PropTypes.object),
  educationalManagementOptions: PropTypes.arrayOf(PropTypes.object),
  typeOfUrbanizationOptions: PropTypes.arrayOf(PropTypes.object),
  costOptions: PropTypes.arrayOf(PropTypes.object),
  genreOptions: PropTypes.arrayOf(PropTypes.object),
  activitiesOptions: PropTypes.arrayOf(PropTypes.object),
  capturingData: PropTypes.object,
  setCapturingData: PropTypes.func,
  setOpen: PropTypes.func,
  setAlert: PropTypes.func,
}.isRequired;
