import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from 'assets/base64img/logoVacantedColorBase64';

function formatDate(str) {
  const splitDate = str.split(/\D/g);
  return [splitDate[2], splitDate[1], splitDate[0]].join('/');
}

function tableBody(report, columns) {
  const result = [];
  result.push(columns);
  report.info.forEach((item) => {
    if (Object.keys(item)[0] === 'Visualizaciones de ficha') {
      result.push(['Visualizaciones de ficha', '-', Object.values(item)[0]]);
    }
    if (Object.keys(item)[0] === 'Solicitud de entrevistas') {
      result.push(['Solicitud de entrevistas', '-', Object.values(item)[0]]);
    }
    if (Object.keys(item)[0] === 'Solicitud de agenda de visita guiada') {
      result.push([
        'Solicitud de agenda de visita guiada',
        '-',
        Object.values(item)[0],
      ]);
    }
    if (Object.keys(item)[0] === 'Postulaciones solicitadas') {
      result.push(['Postulaciones solicitadas', '-', Object.values(item)[0]]);
    }
    if (Object.keys(item)[0] === 'Vacantest hasta 5') {
      result.push([
        'Cantidad de veces que se mostró en Test de Compatibilidad',
        'Dentro de los 5 primeros',
        Object.values(item)[0],
      ]);
    }
    if (Object.keys(item)[0] === 'Vacantest hasta 15') {
      result.push([
        'Cantidad de veces que se mostró en Test de Compatibilidad',
        'Dentro de los 15 primeros',
        Object.values(item)[0],
      ]);
    }
    if (Object.keys(item)[0] === 'Vacantest hasta 50') {
      result.push([
        'Cantidad de veces que se mostró en Test de Compatibilidad',
        'Dentro de los 50 primeros',
        Object.values(item)[0],
      ]);
    }
    if (Object.keys(item)[0] === 'Búsquedas guardadas hasta 5') {
      result.push([
        'Cantidad de veces que se mostró en Búsqueda Inteligente',
        'Dentro de los 5 primeros',
        Object.values(item)[0],
      ]);
    }
    if (Object.keys(item)[0] === 'Búsquedas guardadas hasta 15') {
      result.push([
        'Cantidad de veces que se mostró en Búsqueda Inteligente',
        'Dentro de los 15 primeros',
        Object.values(item)[0],
      ]);
    }
    if (Object.keys(item)[0] === 'Búsquedas guardadas hasta 50') {
      result.push([
        'Cantidad de veces que se mostró en Búsqueda Inteligente',
        'Dentro de los 50 primeros',
        Object.values(item)[0],
      ]);
    }
  });
  return result;
}

const tableHeader = [
  {
    text: 'Tipo de Información',
    bold: true,
    fontSize: 12,
  },
  {
    text: 'Filtro',
    bold: true,
    fontSize: 12,
  },
  {
    text: 'Resultado',
    bold: true,
    fontSize: 12,
  },
];

export default function SchoolReportPDF(reportData) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const data = reportData[0];

  const content = [
    {
      image: logo,
      width: 115,
      alignment: 'center',
      margin: [0, 0, 0, 20],
    },
    {
      text: 'Informe de Interacciones por Centro Educativo',
      style: 'header',
      lineHeight: 1.5,
      alignment: 'center',
      fontSize: 16,
    },
    {
      text: `Periodo ${formatDate(data.period.startDate)} - ${formatDate(data.period.endDate)}`,
      fontSize: 9,
      alignment: 'center',
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      canvas: [
        {
          type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1,
        },
      ],
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      text: data.schoolName,
      bold: true,
      lineHeight: 2,
      fontSize: 14,
      alignment: 'center',
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', 'auto'],
        body: tableBody(data, tableHeader),
        alignment: 'center',
      },
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      canvas: [
        {
          type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1,
        },
      ],
    },
    {
      text: ' ',
      lineHeight: 1.5,
    },
    {
      columns: [
        {
          text: `Reporte generado el ${new Date().toLocaleDateString('es-AR')}`,
          fontSize: 9,
          alignment: 'left',
          lineHeight: 2,
        },
        {
          text: `Reporte generado por usuario ${data.username}`,
          fontSize: 9,
          alignment: 'right',
          lineHeight: 2,
        },
      ],
    },
  ];

  function footer() {
    return [
      {
        text: `© ${new Date().getFullYear()} Vacanted.com`,
        alignment: 'center',
        fontSize: 9,
      },
    ];
  }

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 50, 40, 45],

    content,
    footer,
  };

  pdfMake
    .createPdf(docDefinition)
    .download(`${data.schoolName.split(' ').join('')}${new Date().toISOString()}.pdf`);
}
