import React, { useState, useEffect } from 'react';
import {
  Button, Container, Card, Col, Row, Form,
} from 'react-bootstrap';
import { formatISO9075 } from 'date-fns';
import Select from 'react-select';

import dateRange from 'dist/Utils/dateRanges';
import filterUtils from 'dist/Utils/filters';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import getSchoolVacantestsByDateFilter from 'hooks/getSchoolVacantestsByDateFilter';
import getSchoolsByCountry from 'hooks/getSchoolsByCountry';
import api from 'Services/api';

import SchoolVacantestsReportExcel from './components/SchoolVacantestsReportExcel';
import SchoolVacantestsReportPDF from './components/SchoolVacantestsReportPDF';

const customDateInitialState = {
  startDate: '2021-01-01',
  endDate: formatISO9075(new Date(), { representation: 'date' }),
};

export default function ReportBySchool() {
  const [schoolOptions, setSchoolOptions] = useState();
  const [selectedCountry, setSelectedCountry] = useState(101);
  const [selectedSchool, setSelectedSchool] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [customDateRange, setCustomDateRange] = useState(customDateInitialState);

  function getSchoolOptions(schools) {
    return setSchoolOptions(schools);
  }

  const dateOptions = [
    { value: 'all_history', label: 'Todo el historial' },
    { value: 'all_year', label: 'Todo el año' },
    { value: 'last_six_months', label: 'Últimos 6 meses' },
    { value: 'last_three_months', label: 'Últimos 3 meses' },
    { value: 'last_thirty_days', label: 'Últimos 30 días' },
    { value: 'custom', label: 'Periodo personalizado' },
  ];

  function handleDateSelectChange(e) {
    const { value } = e;
    return setDateFilter(value);
  }

  function handleSchoolSelectChange(e) {
    return setSelectedSchool(e.value);
  }

  function handleCountrySelectChange(e) {
    return setSelectedCountry(e.value);
  }

  function handleInitialDateChange(e) {
    const { value } = e.target;
    return setCustomDateRange({ ...customDateRange, startDate: value });
  }

  function handleFinalDateChange(e) {
    const { value } = e.target;
    return setCustomDateRange({ ...customDateRange, endDate: value });
  }

  function buildData(reportData) {
    const vacantests = reportData.vacantests.map((vacantest) => ({
      Fecha: vacantest.created_at,
      Email: vacantest.email,
      Educacion: vacantest.context ? JSON.stringify(vacantest.context.educLevel.map((ed) => `${ed.name} (${ed.qty})`)) : '-',
      Posición: vacantest.result ? vacantest.result.position : '-',
      'Distancia (Kms)': vacantest.result ? vacantest.result.distance : '-',
      Afinidad: vacantest.result ? `${vacantest.result.affinity.totalAffinity}%` : '-',
    }));
    return { vacantests };
  }

  async function getAndBuildData(selectedDateRange) {
    const vacantests = await getSchoolVacantestsByDateFilter(
      selectedDateRange, selectedSchool.id,
    );
    return buildData(vacantests);
  }

  async function handleClick(format) {
    const { user } = getDataFromLocalStorage();
    const selectedDateRange = filterUtils.getDateValueFromFilter(
      dateFilter,
      dateRange,
      customDateRange,
    );
    const data = await getAndBuildData(selectedDateRange);
    return format === 'excel' ? SchoolVacantestsReportExcel(data, selectedSchool) : SchoolVacantestsReportPDF(data, selectedSchool, selectedDateRange, user);
  }

  useEffect(async () => {
    const fetchData = await getSchoolsByCountry(selectedCountry);

    return getSchoolOptions(fetchData);
  }, [selectedCountry]);

  const updateVacantestOcurrences = async () => {
    const { token } = getDataFromLocalStorage('vacanted');

    if (selectedSchool) {
      await api.post(`/school/vacantest/${selectedSchool.id}`, {}, {
        headers: {
          authorization: token,
        },
      });
    }
  };

  useEffect(async () => {
    updateVacantestOcurrences();
  }, [selectedSchool]);

  return (
    <Container style={{ height: '100vh' }}>
      <h1 style={{ margin: '0', padding: '30px 0 15px 0' }}>Detalle de Vacantests por Centro Educativo</h1>
      { !schoolOptions
        ? <p>Loading...</p>
        : (
          <Card style={{ padding: '0 1rem' }}>
            <Row style={{ padding: '1rem 0' }}>
              <Col md="4">
                <Form.Label>País</Form.Label>
                <Select
                  placeholder="País"
                  options={[{ value: 102, label: 'Argentina' }, { value: 101, label: 'Uruguay' }]}
                  name="country"
                  onChange={(e) => handleCountrySelectChange(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <Form.Label>ID VACANTED o Nombre Institución</Form.Label>
                <Select
                  placeholder="ID - Nombre"
                  options={schoolOptions.map((school) => ({ value: school, label: `${school.id} - ${school.name}` }))}
                  name="schoolId"
                  onChange={(e) => handleSchoolSelectChange(e)}
                />
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>Indique periodo de tiempo</Form.Label>
                  <Select
                    isDisabled={!selectedSchool}
                    options={dateOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccionar opción"
                    onChange={(e) => handleDateSelectChange(e)}
                  />
                </Form.Group>
                {
                  (dateFilter === 'custom')
                    ? (
                      <>
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Desde</Form.Label>
                              <Form.Control type="date" onChange={(e) => handleInitialDateChange(e)} />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Hasta</Form.Label>
                              <Form.Control type="date" onChange={(e) => handleFinalDateChange(e)} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )
                    : ''
                }
              </Col>
            </Row>
            <Row style={{ padding: '1rem 0', textAlign: 'right' }}>
              <Col>
                <Button
                  disabled={!selectedSchool || !dateFilter}
                  style={{ backgroundColor: '#36a7e8', border: 'none', marginRight: '10px' }}
                  onClick={() => handleClick('excel')}
                >
                  EXPORTAR EXCEL
                </Button>
                <Button
                  disabled={!selectedSchool || !dateFilter}
                  style={{ backgroundColor: '#36a7e8', border: 'none' }}
                  onClick={() => handleClick('pdf')}
                >
                  EXPORTAR PDF
                </Button>
              </Col>
            </Row>
          </Card>
        )}
    </Container>
  );
}
