import React, { useState, useEffect } from 'react';
import getUserData from 'hooks/getUserData';
import { Card, Button } from 'react-bootstrap';

import DropDownSelect from 'components/DropDownSelect';

import EventsTable from 'components/EventsTable/EventsTable';

import api from 'Services/api';
import CreateVisitModal from '../../components/createVisitModal/index';
import styles from './styles.module.css';

import opts from './options.json';

const { optionsStatus } = opts;

export default function SchoolEvents() {
  const [isCreateVisitModalOpen, setIsCreateVisitModalOpen] = useState();
  const [visits, setVisits] = useState([]);
  const [filteredVisits, setFilteredVisists] = useState([]);
  const [schoolId, setSchoolId] = useState();
  const [token, setToken] = useState();
  const [statusSelect, setStatusSelect] = useState({ value: 'Activas', label: 'Activas' });

  useEffect(() => {
    async function getUserInfo() {
      const user = await getUserData();
      setSchoolId(user.data.user.school_id);
      setToken(user.data.token);
    }
    getUserInfo();
  }, []);
  function search() {
    if (statusSelect.value === 'Todos') {
      setFilteredVisists(visits);
      return;
    }
    const filterType = statusSelect.value === 'Activas';
    const filter = visits.filter((value) => (
      value.active === filterType
    ));
    setFilteredVisists(filter);
  }
  useEffect(() => {
    const getVisits = async () => {
      const response = await api.get(`/school/calendar/${schoolId}`);
      setVisits(response.data);
      setFilteredVisists(response.data.filter((value) => (
        value.active === true
      )));
    };
    getVisits();
  }, [schoolId]);

  if (!visits) {
    return (
      <p>Loading...</p>
    );
  }

  function handleCloseCreateVisitModal() {
    setIsCreateVisitModalOpen(false);
  }
  function handleOpenCreateVisitModal() {
    setIsCreateVisitModalOpen(true);
  }

  function handleDeleteVisit(visitId) {
    const newVisits = [...filteredVisits];
    const index = filteredVisits.findIndex((visit) => visit.id === visitId);
    const config = {
      headers: {
        authorization: token,
      },
    };
    newVisits.splice(index);
    api.delete(`/school/calendar/${schoolId}/${visitId}`, config);

    setFilteredVisists(newVisits);
  }

  return (
    <div className={styles.bodyContainer}>
      <h1>Gestión de Visitas Guiadas en Ficha</h1>
      <p>
        Aquí se pueden crear novas visitas o acceder a las visitas activas o vencidas.
      </p>
      <div className={styles.createVisitBtnContainer}>
        <button
          type="button"
          className={styles.createVisitBtn}
          onClick={() => handleOpenCreateVisitModal()}
        >
          CREAR NUEVA VISITA GUIADA
        </button>
      </div>
      <Card className={styles.card}>
        <Card.Body className={styles['card-body']}>
          <div className={styles.headerContainer}>
            <div className={styles.sendStatus}>
              <DropDownSelect
                filters={optionsStatus}
                pageSelect={statusSelect}
                setPageSelect={setStatusSelect}
              />
            </div>

            <div className={styles.buttonContainer}>
              <Button onClick={() => search()}>Buscar</Button>
            </div>
          </div>
        </Card.Body>
        <hr />
        <Card.Body className={styles.reactTable}>
          <EventsTable
            data={filteredVisits}
            // eslint-disable-next-line react/jsx-no-bind
            deleteVisit={handleDeleteVisit}
          />
        </Card.Body>
      </Card>

      <CreateVisitModal
        onRequestClose={() => handleCloseCreateVisitModal()}
        isOpen={isCreateVisitModalOpen}
        visits={visits}
        setVisits={setVisits}
      />

    </div>
  );
}
