import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './button.scss';

const classNames = {
  default: 'button',
  primary: 'button--primary',
  success: 'button--success',
  light: 'button--light',
  info: 'button--info',
  dark: 'button--dark',
  warning: 'button--warning',
  danger: 'button--danger',
  inverse: 'button--inverse',
  outline: 'button--outline',
  transparent: 'button--transparent',
  large: 'button--large',
};

const Button = forwardRef(({
  className, children, type, outline, size, onClick, disabled, submit,
}, ref) => {
  const _className = classnames(className, classNames.default, {
    [classNames.outline]: outline,
    [classNames.large]: size === 'large',
  }, classNames[type]);

  return (
    <button
      ref={ref}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      className={_className}
      onClick={onClick}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'success',
    'light',
    'info',
    'dark',
    'warning',
    'danger',
    'inverse',
    'transparent',
  ]),
  size: PropTypes.oneOf([
    'default',
    'large',
  ]),
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  submit: PropTypes.bool,
};

Button.defaultProps = {
  className: null,
  type: 'default',
  size: 'default',
  outline: false,
  onClick: null,
  disabled: false,
  children: null,
  submit: false,
};

export default Button;
