import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import style from './styles.module.css';
import './DropDownSelect.css';

function DropDownSelect({
  pageSelect,
  setPageSelect,
  filters,
  isDisabled,
  // type,
}) {
  function removeNull() {
    const newFilters = [];
    filters.forEach((object) => {
      if (object.name !== '') newFilters.push(object);
    });

    return newFilters;
  }

  return (
    <div className={style.dropDownHeader}>
      <Select
        className={`${style['react-select']} ${style.primary}`}
        classNamePrefix="react-select"
        name="pageSelect"
        value={pageSelect}
        onChange={(value) => setPageSelect(value)}
        options={removeNull().map((prop) => ({
          value: prop.id ? prop.id : prop,
          label: `${prop.id ? prop.name : prop}`,
        }))}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default DropDownSelect;

DropDownSelect.propTypes = {
  setPageSelect: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  pageSelect: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    key: PropTypes.string,
  }),
}.isRequired;
