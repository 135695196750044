import React, { useState } from 'react';
import PropTypes from 'prop-types';

import schoolUpdate from 'dist/Collections/schoolUpdate';

import CheckboxGroup from 'components/CheckboxGroup';
import TextAreaWithTitle from 'components/TextAreaWithTitle';
import DropDownSelect from 'components/DropDownSelect';
import AreaConfirmData from 'components/AreaConfirmData';

import styles from './styles.module.css';

export default function Religion({
  infoCentro,
  religionOptions,
  capturingData,
  setCapturingData,
  setAlert,
  setOpen,
}) {
  const [characteristics, setCharacteristics] = useState();

  const [religious, setReligious] = useState(
    !infoCentro?.religious ? { Laica: true } : { Religiosa: true },
  );

  const [religion, setReligion] = useState({
    label: infoCentro?.religion.name,
    value: infoCentro?.religion.id,
    disabled: !infoCentro?.religious,
  });

  const { token } = JSON.parse(localStorage.getItem('vacanted'));

  const characteristicsOptions = () => {
    const options = [{ id: 1, activity: 'Laica' }, { id: 2, activity: 'Religiosa' }];
    return options;
  };

  async function sendData() {
    let data = {};

    if (religious.Laica) {
      data = {
        religion_id: 1,
        religious: null,
      };
    }

    if (religious.Religiosa) {
      data = {
        religion_id: religion.value,
        religious: true,
      };
    }

    setCapturingData({
      ...capturingData,
      religion: {
        loading: true,
        saveInProgress: false,
      },
    });

    await schoolUpdate.updateSchool(data, infoCentro, '', infoCentro.id, token)
      .then((resp) => {
        if (resp.data.message === 'School updated successfully') {
          setCapturingData({
            ...capturingData,
            religion: {
              saveInProgress: false,
            },
          });
          setAlert({
            message: '¡Información escolar actualizada!',
            type: 'success',
          });
          setOpen(true);
        }
      })
      .catch((err) => {
        setCapturingData({
          ...capturingData,
          religion: {
            saveInProgress: true,
          },
        });
        setAlert({
          message: '¡Hubo un error al actualizar la información!',
          type: 'error',
        });
        setOpen(true);
        return err;
      });
  }

  function cancelData() {
    setReligious(
      !infoCentro?.religious ? { Laica: true } : { Religiosa: true },
    );
    setReligion({
      label: infoCentro.religion.name,
      value: infoCentro.religion.id,
      disabled: !infoCentro.religious,
    });

    setCharacteristics('');

    setCapturingData({
      ...capturingData,
      religion: {
        saveInProgress: false,
      },
    });
  }

  function optionsCheck(e) {
    if (e?.Religiosa) {
      setReligion({
        label: infoCentro.religion.name,
        value: infoCentro.religion.id,
        disabled: false,
      });
    }

    if (e?.Laica) {
      setReligion({
        label: '',
        value: '',
        disabled: true,
      });
    }

    setReligious(e);
  }

  return (
    <div className={styles.religionContainer}>
      <div className={`${styles.descripción} ${styles.replyContainer}`}>
        <div className={`${styles.titleContent} ${styles.replyContainer}`}>
          <p>
            Verifique y si es necesario edite la informacion religiosa si corresponde.
          </p>
          <CheckboxGroup
            title="Seleccione las opciones que apliquen a su Institución"
            characteristics={characteristicsOptions}
            setOptionsSelected={(e) => optionsCheck(e)}
            optionsSelected={religious}
            isDisabled={!capturingData?.religion?.saveInProgress}
            oneChoise
            type="religion"
          />
        </div>
      </div>
      <div className={`${styles.withDropDownContent} ${styles.replyContainer}`}>
        <DropDownSelect
          pageSelect={religion}
          setPageSelect={setReligion}
          filters={religionOptions}
          isDisabled={!capturingData?.religion?.saveInProgress || religion.disabled}
        />
        <TextAreaWithTitle
          title="Descripción acerca de la religión"
          placeholder="Ingrese la descripción"
          action={setCharacteristics}
          value={characteristics}
          rewrite={capturingData?.religion?.saveInProgress}
        />
      </div>
      <AreaConfirmData
        capturingData={capturingData?.religion}
        action={() => sendData()}
        cancel={() => cancelData()}
      />
    </div>
  );
}

Religion.propTypes = {
  infoCentro: PropTypes.objectOf(PropTypes.object),
  religionOptions: PropTypes.arrayOf(PropTypes.object),
  capturingData: PropTypes.object,
  setCapturingData: PropTypes.func,
  setOpen: PropTypes.func,
  setAlert: PropTypes.func,
}.isRequired;
