import React from 'react';

import PropTypes from 'prop-types';

import TableReceivedApplications from 'components/TableReceivedApplications';

export default function ApplicationsInterview({ data, reloadData }) {
  return (
    <div>
      <h4>
        Postulaciones en proceso de entrevistas
      </h4>
      <p>
        Aquí se muestran las postulaciones que fueron descargadas y están en proceso de entrevistas.
      </p>
      <TableReceivedApplications data={data} type="ApplicationsInterview" reloadData={reloadData} />
    </div>
  );
}

ApplicationsInterview.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  reloadData: PropTypes.func,
}.isRequired;
