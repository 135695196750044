import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane, faFileUpload, faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import api from 'Services/api';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import Button from '../../Button/Button';
import SimpleFileLoader from '../../Chat/SimpleFileLoader';
import useFirebase from '../../Chat/useFirebase';

const classNames = {
  content: 'referral-details__content',
  contentPanel: 'referral-details__content-panel',
  toolBar: 'referral-details__toolbar',
  sendInfoButton: 'referral-details__send-info-button',
  sendInfoTitle: 'referral-details__send-info-title',
  sendInfoInput: 'referral-details__send-info-input',
  sendInfoInputAttach: 'referral-details__send-info-input__attach',
  sendInfoInputContainer: 'referral-details__send-info-input__container',
  sendInfoButtonContainer: 'referral-details__send-info-button__container',
};

const ContactPanel = ({ referral, callBack }) => {
  // ToDo: set this with !referral.chatId
  const [sendInfoOpen, setSendInfoOpen] = useState(referral?.stateId === 1);
  const [sendInfoText, setSendInfoText] = useState('');
  const [sendInfoAttachments, setSendInfoAttachments] = useState([]);
  const [showPreviousFilesDialog, setShowPreviousFilesDialog] = useState(false);
  const [sendingState, setSendingState] = useState(false);
  const toggleSendInfoOpen = () => setSendInfoOpen(!sendInfoOpen);
  const { token, user } = getDataFromLocalStorage('vacanted');
  const { firebaseInfo } = useFirebase();

  const addAttachment = (attachment) => {
    setSendInfoAttachments([attachment, ...sendInfoAttachments]);
  };

  const removeAttachment = (attachmentName) => {
    const newAttachments = sendInfoAttachments.filter(({ name }) => name !== attachmentName);
    setSendInfoAttachments(newAttachments);
  };

  const handleSelectedFile = (selectedFile) => {
    if (selectedFile) {
      const { name, fullPath } = selectedFile;
      addAttachment({ name, fullPath });
    }
    setShowPreviousFilesDialog(false);
  };

  const afterContact = (result) => {
    setSendInfoOpen(false);
    callBack(result);
  };

  const handleSendContact = () => {
    setSendingState(true);
    api.post(`/school/${user.school_id}/referrals/${referral?.id}/contact`,
      {
        message: sendInfoText,
        attachments: sendInfoAttachments.map((att) => ({ firebase_path: att.fullPath })),
      },
      { headers: { authorization: token } })
      .then(() => {
        referral.refresh();
        afterContact(true);
      })
      .catch(() => afterContact(false))
      .finally(() => setSendingState(false));
  };

  const handleHide = () => {
    setSendInfoOpen(false);
  };

  return (
    <>
      <div className={classNames.toolBar}>
        {!sendInfoOpen && (
        <Button type="primary" outline size="large" className={classNames.sendInfoButton} onClick={toggleSendInfoOpen}>
          <FontAwesomeIcon icon={faPaperPlane} />
          Enviar información institucional
        </Button>
        )}
      </div>
      {sendInfoOpen && (
        <>
          <div className={classNames.sendInfoTitle}>Enviar información institucional</div>
          <div className={classNames.sendInfoInputContainer}>
            <textarea
              placeholder="Escribe un mensaje..."
              className={classNames.sendInfoInput}
              value={sendInfoText}
              onChange={(e) => setSendInfoText(e.target.value)}
            />
            {sendInfoAttachments.map(({ name }) => (
              <Button className={classNames.sendInfoInputAttach} type="success" outline onClick={() => removeAttachment(name)}>
                <FontAwesomeIcon icon={faPaperclip} />
                {name}
              </Button>
            ))}
          </div>
          <div className={classNames.sendInfoButtonContainer}>
            <Button disabled={!sendInfoText || sendingState} type="primary" size="default" onClick={handleSendContact}>
              Enviar
            </Button>
            <Button outline type="primary" size="default" onClick={() => setShowPreviousFilesDialog(true)}>
              <FontAwesomeIcon className="message-send-icon clickable" icon={faFileUpload} size="lg" />
              {' '}
              Adjuntar
            </Button>
            <Button type="light" size="default" onClick={handleHide}>
              Ocultar
            </Button>
          </div>
          {showPreviousFilesDialog && (
            <SimpleFileLoader
              memberId={firebaseInfo.id}
              callbackWithFile={handleSelectedFile}
            />
          )}
        </>
      )}
    </>
  );
};

ContactPanel.propTypes = {
  referral: PropTypes.shape({
    stateId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    chatId: PropTypes.string,
    refresh: PropTypes.func,
  }).isRequired,
  callBack: PropTypes.func.isRequired,
};

export default ContactPanel;
