import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../Button/Button';
import ReferralsTableContext from '../../contexts/ReferralsTableContext';

const ViewCell = ({ value }) => {
  const { onSelect } = useContext(ReferralsTableContext);

  return (
    <td className="view-cell">
      <Button
        type="primary"
        outline
        size="large"
        onClick={() => onSelect(value)}
      >
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" className="view-cell__icon" />
        Ver
      </Button>
    </td>
  );
};

ViewCell.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ViewCell;
