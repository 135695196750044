import countryService from '../Services/countryService';

const urlMappings = {
  local: {
    api: 'http://local.vacanted.com.{countryCode}:8080',
    // api: 'https://develop.vacanted.com.{countryCode}/api',
    app: 'http://local.vacanted.com.{countryCode}:3000',
    login: 'http://local.vacanted.com.{countryCode}:3000/auth/login',
  },
  localDev: {
    api: 'https://develop.vacanted.com.{countryCode}/api',
    app: 'http://local.vacanted.com.{countryCode}:3000',
    panel: 'http://local.vacanted.com.{countryCode}:3002',
    schoolPanel: 'http://local.vacanted.com.{countryCode}:3001',
  },
  development: {
    api: 'https://develop.vacanted.com.{countryCode}/api',
    app: 'https://develop.vacanted.com.{countryCode}',
    login: 'https://develop.vacanted.com.{countryCode}/auth/login',
  },
  qa: {
    api: 'https://qa.vacanted.com.{countryCode}/api',
    app: 'https://qa.vacanted.com.{countryCode}',
    login: 'https://qa.vacanted.com.{countryCode}/auth/login',
  },
  production: {
    api: 'https://vacanted.com.{countryCode}/api',
    app: 'https://vacanted.com.{countryCode}',
    login: 'https://vacanted.com.{countryCode}/auth/login',
  },
};

const vacantedEnvironment = process.env.REACT_APP_VACANTED_ENV || 'local';

if (!urlMappings[vacantedEnvironment]) {
  throw new Error(`Env ${vacantedEnvironment} is not valid to initialize app`);
}

export const getUrl = (component) => {
  const detectedCountryCode = countryService.getCurrentCountryCode();
  const urlTemplate = urlMappings[vacantedEnvironment][component];
  const url = urlTemplate.replace('{countryCode}', detectedCountryCode);
  return url;
};

export const getEnv = () => vacantedEnvironment;
