import React from 'react';
import PropTypes from 'prop-types';

import TableReceivedApplications from 'components/TableReceivedApplications';

export default function DisabledApplications({ data, reloadData }) {
  return (
    <div>
      <h4>
        Postulaciones recibidas activas
      </h4>
      <p>
        Aquí se muestran las postulaciones que están en proceso.
        Su institución cuenta con 30 días de plazo para descargar el informe de familia.
      </p>
      <TableReceivedApplications data={data} type="DisabledApplications" reloadData={reloadData} />
    </div>
  );
}

DisabledApplications.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  reloadData: PropTypes.func,
}.isRequired;
