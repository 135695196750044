import api from 'Services/api';
import { getDataFromLocalStorage } from '../dist/Storage/localStorageMethods';

export default async function getSchoolDashboardData() {
  const { user, token } = getDataFromLocalStorage();
  const config = {
    headers: {
      authorization: token,
    },
  };
  const dashboardData = await api.get(`/school/${user?.school_id}/dashboard`, config)
    .then((resp) => (resp.data))
    .catch(() => null);

  return dashboardData;
}
