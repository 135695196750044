import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';

import schoolUpdate from 'dist/Collections/schoolUpdate';

import CheckboxGroup from 'components/CheckboxGroup';
import TextAreaWithTitle from 'components/TextAreaWithTitle';
import AreaConfirmdata from 'components/AreaConfirmData';

import styles from './styles.module.css';

export default function ExtracurricularActivities({
  infoCentro,
  activitiesOptions,
  capturingData,
  setCapturingData,
}) {
  const extracurricularActivities = infoCentro?.activities?.extracurricularActivities;
  const extracurricularCharacteristics = infoCentro?.characteristics?.extracurricularActivities;

  const [generalInfo, setGeneralInfo] = useState(
    extracurricularCharacteristics?.generalInfo
      ? extracurricularCharacteristics?.generalInfo
      : '',
  );
  const [useArtisticActivities, setArtisticActivities] = useState(
    extracurricularCharacteristics?.useArtisticActivities
      ? extracurricularCharacteristics?.useArtisticActivities
      : '',
  );
  const [useCulturalActivities, setCulturalActivities] = useState(
    extracurricularCharacteristics?.useCulturalActivities
      ? extracurricularCharacteristics?.useCulturalActivities
      : '',
  );
  const [useSportActivities, setSportActivities] = useState(
    extracurricularCharacteristics?.useSportActivities
      ? extracurricularCharacteristics?.useSportActivities
      : '',
  );

  const [artisticActivitiesOptions, setArtisticActivitiesOptions] = useState(
    extracurricularActivities?.artisticActivitiesOptions
      ? extracurricularActivities?.artisticActivitiesOptions
      : '',
  );
  const [actividadesCulturalesOptions, setActividadesCulturalesOptions] = useState(
    extracurricularActivities?.actividadesCulturalesOptions
      ? extracurricularActivities?.actividadesCulturalesOptions
      : '',
  );
  const [actividadesDeportivasOptions, setActividadesDeportivasOptions] = useState(
    extracurricularActivities?.actividadesDeportivasOptions
      ? extracurricularActivities?.actividadesDeportivasOptions
      : '',
  );

  const artisticActivities = () => {
    const array = activitiesOptions.find(
      (arrayActivity) => arrayActivity.type === 'Actividades Artisticas',
    );
    return array.activity;
  };

  const actividadesCulturales = () => {
    const array = activitiesOptions.find(
      (arrayActivity) => arrayActivity.type === 'Actividades Culturales',
    );
    return array.activity;
  };

  const actividadesDeportivas = () => {
    const array = activitiesOptions.find(
      (arrayActivity) => arrayActivity.type === 'Actividades Deportivas',
    );
    return array.activity;
  };

  function sendData() {
    schoolUpdate(infoCentro, {
      activities: {
        ...infoCentro.activities,
        extracurricularActivities: {
          ...infoCentro.activities.extracurricularActivities,
          artisticActivitiesOptions,
          actividadesCulturalesOptions,
          actividadesDeportivasOptions,
        },
      },
      characteristics: {
        ...infoCentro.characteristics,
        extracurricularActivities: {
          ...infoCentro.characteristics.extracurricularActivities,
          generalInfo,
          useArtisticActivities,
          useCulturalActivities,
          useSportActivities,
        },
      },
    });

    setCapturingData({
      ...capturingData,
      extracurricularActivities: {
        loading: false,
        saveInProgress: false,
      },
    });
  }

  function cancelData() {
    setGeneralInfo(
      extracurricularCharacteristics?.generalInfo
        ? extracurricularCharacteristics?.generalInfo
        : '',
    );
    setArtisticActivities(
      extracurricularCharacteristics?.useArtisticActivities
        ? extracurricularCharacteristics?.useArtisticActivities
        : '',
    );
    setCulturalActivities(
      extracurricularCharacteristics?.useCulturalActivities
        ? extracurricularCharacteristics?.useCulturalActivities
        : '',
    );
    setSportActivities(
      extracurricularCharacteristics?.useSportActivities
        ? extracurricularCharacteristics?.useSportActivities
        : '',
    );
    setArtisticActivitiesOptions(
      extracurricularActivities.artisticActivitiesOptions
        ? extracurricularActivities.artisticActivitiesOptions
        : '',
    );
    setActividadesCulturalesOptions(
      extracurricularActivities.actividadesCulturalesOptions
        ? extracurricularActivities.actividadesCulturalesOptions
        : '',
    );
    setActividadesDeportivasOptions(
      extracurricularActivities.actividadesDeportivasOptions
        ? extracurricularActivities.actividadesDeportivasOptions
        : '',
    );

    setCapturingData({
      ...capturingData,
      extracurricularActivities: {
        saveInProgress: false,
      },
    });
  }

  return (
    <div className={styles.mainFeatures}>
      <TextAreaWithTitle
        title="Información general sobre las actividades extracurriculares de la institución"
        placeholder="Ingrese una descripción si aplica"
        action={setGeneralInfo}
        value={generalInfo}
        rewrite={capturingData?.extracurricularActivities?.saveInProgress}
      />
      <hr />
      <div className={`${styles.descripción} ${styles.replyContainer}`}>
        <CheckboxGroup
          title="Actividades artísticas"
          characteristics={artisticActivities}
          setOptionsSelected={setArtisticActivitiesOptions}
          optionsSelected={artisticActivitiesOptions}
          isDisabled={!capturingData?.extracurricularActivities?.saveInProgress}
        />
        <TextAreaWithTitle
          title="Descripción"
          placeholder="Ingrese una descripción si aplica"
          action={setArtisticActivities}
          value={useArtisticActivities}
          rewrite={capturingData?.extracurricularActivities?.saveInProgress}
        />
      </div>
      <hr />
      <div className={`${styles.descripción} ${styles.replyContainer}`}>
        <CheckboxGroup
          title="Actividades culturales"
          characteristics={actividadesCulturales}
          setOptionsSelected={setActividadesCulturalesOptions}
          optionsSelected={actividadesCulturalesOptions}
          isDisabled={!capturingData?.extracurricularActivities?.saveInProgress}
        />
        <TextAreaWithTitle
          title="Descripción"
          placeholder="Ingrese una descripción si aplica"
          action={setCulturalActivities}
          value={useCulturalActivities}
          rewrite={capturingData?.extracurricularActivities?.saveInProgress}
        />
      </div>
      <hr />
      <div className={`${styles.descripción} ${styles.replyContainer}`}>
        <CheckboxGroup
          title="Actividades deportivas"
          characteristics={actividadesDeportivas}
          setOptionsSelected={setActividadesDeportivasOptions}
          optionsSelected={actividadesDeportivasOptions}
          isDisabled={!capturingData?.extracurricularActivities?.saveInProgress}
        />
        <TextAreaWithTitle
          title="Descripción"
          placeholder="Ingrese una descripción si aplica"
          action={setSportActivities}
          value={useSportActivities}
          rewrite={capturingData?.extracurricularActivities?.saveInProgress}
        />
      </div>
      <AreaConfirmdata
        capturingData={capturingData.extracurricularActivities}
        action={() => sendData()}
        cancel={() => cancelData()}
      />
    </div>

  );
}

ExtracurricularActivities.propTypes = {
  infoCentro: PropTypes.objectOf(object),
  activitiesOptions: PropTypes.arrayOf(object),
  capturingData: PropTypes.object,
  setCapturingData: PropTypes.func,
}.isRequired;
